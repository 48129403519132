import { Component, HostListener, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/services/maintenance.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import * as _ from 'lodash';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { CONSTANT } from 'src/app/helpers/constants';
import { environment } from 'src/environments/environment';
import { AnimationOptions } from 'ngx-lottie';
import { HotelWebConfig } from 'src/app/@types/app';
import { UTILS } from 'src/app/helpers/utils';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.sass','../../../styles.sass']
})
export class MaintenanceComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }

  title:string = 'Maintenance Reservations';
  hotels: any[] = [];
  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;

  categories: any[] = [];
  selectedCategory:any;

  searchResult: any[] = [];
  prevRequest:any;
  totalCount:number = 0;
  currentCount:number = 0;
  remainingCount:number = 0;

  minEnd:any = new Date();
  prevStartDate:any = null;
  prevEndDate:any = null;
  startDate:any = new Date(new Date().setHours(16, 0, 0, 0));
  endDate:any = new Date(new Date(new Date().getTime() + 24*3600*1000).setHours(12, 0, 0, 0));

  isSearching:boolean = false;
  showResult:boolean = false;
  isFormValid:boolean = false;

  token:any = window.localStorage.getItem('jwt');
  isCleaner:boolean = false;

  datepickerCustom: DatepickerDateCustomClasses[];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  constructor(
    private maintenanceService: MaintenanceService,
    private toast: CustomToastService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private storeService: StoreService,
    public utils: UTILS
  ) { 
    this.datepickerCustom = []
  }

  ngOnInit(): void {
    this.getData();
  }

  updateDatepicker() {
    let custom = [
      {date: this.startDate, classes: ['selectedDate', 'start']},
    ]
    let tmpTime = this.startDate.getTime() + 24*3600*1000;
    while(tmpTime < this.endDate.getTime()) {
      custom.push({
        date: new Date(tmpTime), classes: ['dateRange']
      });
      tmpTime += 24*3600*1000;
    }
    custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
    this.datepickerCustom = custom
  }

  getData(): void {
    this.maintenanceService.getHotels().subscribe(data => {
      let userRoles = this.tokenStorageService.getRole();
      if(userRoles && ["CLEANER"].includes(userRoles)) {
        this.isCleaner = true;
      }
      if(data) {
        this.hotels = data;
        let tmpHotelList:MenuType[] = [{
          label: "All",
          value: "all",
          disabled: false
        }];
        data.forEach((hotel:any) => {
          tmpHotelList.push({
            label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
            value: hotel.hotelId.toString(),
            disabled: false
          })
        })
        this.hotelList = tmpHotelList;
        this.selectAllHotel();
        
        this.startDate = new Date(this.startDate.setHours(data[0].defaultCheckInTime.slice(0, 2),0,0,0));
        this.endDate = new Date(this.endDate.setHours(data[0].defaultCheckOutTime.slice(0, 2),0,0,0));
        this.validateDate();
      }
    });
    this.maintenanceService.getCategories().subscribe(data => {
      this.categories = data.map((v: any) => ({...v, checked: false}));
    })
  }

  selectHotel(item:MenuType) {
    this.selectedHotel = item;
  }
  selectAllHotel() {
    this.selectedHotel = this.hotelList[0]
  }

  searchForm = new UntypedFormGroup({
    selectedCategory: new UntypedFormControl('', [Validators.required]),
  })

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  setDateFromDropdown(value:Date, type:string) {
    switch (type) {
      case "startDate":
        this.startDate = value;
        break;
      case "endDate":
        this.endDate = value;
        break;
      default:
        break;
    }
  }

  disableSearch() {
    this.isFormValid = false;
  }
  validateDate() {
    if(this.startDate != "Invalid Date" && this.endDate != "Invalid Date") {
      this.isFormValid = true;
      document.querySelector<HTMLInputElement>('input')?.classList.remove('invalid');
    } else {
      this.isFormValid = false;
    }
  }

  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(!this.prevStartDate) {
          this.prevStartDate = this.startDate;
        } else {
          const prevStartMonth = this.prevStartDate.getMonth();
          const newStartMonth = this.startDate.getMonth();
          if(prevStartMonth !== newStartMonth) {
            this.startDate = new Date(this.startDate.setHours(this.prevStartDate.getHours(), this.prevStartDate.getMinutes(), 0, 0));
            this.prevStartDate = this.startDate;
          } else {
            this.prevStartDate = this.startDate;
          }
        }
        if(this.startDate.getTime() >= this.endDate.getTime()) {
          let tmpStartDate = new Date(this.startDate.getTime())
          this.endDate = new Date(tmpStartDate.setHours(this.prevEndDate.getHours(), this.prevEndDate.getMinutes(), 0, 0) + 24*3600*1000);
          this.minEnd = this.startDate;
        }
        break;
      case 'endDate':
        if(!this.prevEndDate) {
          this.prevEndDate = this.endDate;
        } else {
          const prevEndMonth = this.prevEndDate.getMonth();
          const newEndMonth = this.endDate.getMonth();
          if(prevEndMonth !== newEndMonth) {
            this.endDate = new Date(this.endDate.setHours(this.prevEndDate.getHours(), this.prevEndDate.getMinutes(), 0, 0));
            this.prevEndDate = this.endDate;
          } else {
            this.prevEndDate = this.endDate;
          }
        }
        break;
    }
    this.updateDatepicker();
    this.validateDate();
  }

  categoryChange(e:any,name:any) {
    let selectedIndex = this.categories.findIndex((category:any) => {
      return category.name === name;
    })
    this.categories[selectedIndex].checked = e;
    this.selectedCategory = _.map(this.categories,this.getCheckedCategory).filter((item:any) => {return item != 0;}).join(", ");
  }

  getCheckedCategory(item:any) {
    if(item.checked) return item.label; else return 0;
  }

  getCheckedCategoryName(item:any) {
    if(item.checked) return item.name; else return 0;
  }

  onSearch(type:string):void {
    let searchRequest:any;
    if(type == 'new') {
      this.isSearching = true;
      this.showResult = false;
      this.searchResult = [];
      searchRequest = {
        'startDate': formatDate(this.startDate, "yyyy-MM-dd", "en-US"),
        'startTime': formatDate(this.startDate, "HH:mm", "en-US"),
        'endDate': formatDate(this.endDate, "yyyy-MM-dd", "en-US"),
        'endTime': formatDate(this.endDate, "HH:mm", "en-US"),
        'limit': 10,
        'offset': 0,
        'hotelId': null,
        'categories': _.map(this.categories,this.getCheckedCategoryName).filter((item:any) => {return item != 0;}),
      }
      if(this.selectedHotel && this.selectedHotel.value != "all") {
        searchRequest.hotelId = this.selectedHotel.value;
      } else {
        delete searchRequest['hotelId'];
      }
      this.prevRequest = searchRequest;
    } else {
      this.prevRequest.offset += 10;
      searchRequest = this.prevRequest;
    }
    this.maintenanceService.getMaintenance(searchRequest).subscribe(data => {
      data.results.forEach((result:any) => {
        let resultHotel = this.getHotelObjById(result.hotelId)
        let tmpSearchResult = {
          'id': result.id,
          'hotel': resultHotel,
          'startDate': moment(result.startDate).tz(resultHotel.zone).format('DD.MM.YYYY HH:mm'),
          'endDate': moment(result.endDate).tz(resultHotel.zone).format('DD.MM.YYYY HH:mm'),
          'sendStartDate': moment(result.startDate).tz(resultHotel.zone).format('YYYY.MM.DD HH:mm'),
          'sendEndDate': moment(result.endDate).tz(resultHotel.zone).format('YYYY.MM.DD HH:mm'),
          'createdBy': result.username,
          'createdTime': moment(result.created).tz(resultHotel.zone).format('DD.MM.YYYY HH:mm'),
          'category': this.getCategoryObj(result.category).label,
          'reason': result.reason,
          'rooms': _.map(result.rooms,(item) => {return item})
        };
        this.searchResult.push(tmpSearchResult);
      })
      this.totalCount = data.totalCount;
      this.currentCount = this.searchResult.length;
      const tmpRemainingCount = this.totalCount - this.currentCount;
      this.remainingCount = tmpRemainingCount > 10 ? 10 : tmpRemainingCount;
      this.isSearching = false;
      this.showResult = true;
    })
  }

  getHotelObjById(hotelId:number): any {
    const hotelObj = this.hotels.find((obj:any) => {
      return obj.hotelId == hotelId;
    });
    return hotelObj;
  }

  getCategoryObj(getName:any): any {
    const categoryObj = this.categories.find((obj:any) => {
      return obj.name == getName;
    });
    return categoryObj;
  }

  //Convert date
  convertDate(date:any, type:string) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth()+1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    switch (type) {
      case 'YYYY-MM-dd':
        return `${year}-${month}-${day}`;
      case 'dd.MM.YYYY':
        return `${day}.${month}.${year}`;
      case 'MM.YYYY':
        return `${month}.${year}`;
      case 'dd.MM.YYYY hh:mm:ss':
        let hour = ('0' + date.getHours()).slice(-2);
        let minute = ('0' + date.getMinutes()).slice(-2);
        let second = ('0' + date.getSeconds()).slice(-2);
        return `${day}.${month}.${year} ${hour}:${minute}:${second}`;
    }
    return;
  }

  convertStringDate(stringDate:any) {
    let extract = stringDate.split('-');
    return `${extract[2]}.${extract[1]}.${extract[0]}`;
  }

  openUsage(hotelId:number, maintenanceId:number, startDate:any, endDate:any) {
    let usageParams:any = {
      hotelId: hotelId,
      startDate: formatDate(new Date(startDate), 'yyyy-MM-dd', 'en-US'),
      endDate: formatDate(new Date(endDate), 'yyyy-MM-dd', 'en-US'),
      maintenanceReservationId: maintenanceId
    };
    this.router.navigate(['/usage'], {queryParams: usageParams})
  }

}

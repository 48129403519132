import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANT } from 'src/app/helpers/constants';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { TenantService } from 'src/app/services/tenant.service';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { UserService } from 'src/app/services/user.service';
import { UTILS } from 'src/app/helpers/utils';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass','../../../styles.sass']
})
export class DashboardComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }

  tenants: any = [];
  environments: any = [];
  users: any = [];
  siteHostCurrent = window.location.origin;
  isAdminRole = false;
  isNotClientNonAdminRole = true;
  firstName?: any = localStorage.getItem("auth-user");
  loadingContainer: boolean = false;

  loadingActiveUsers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingTenants: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private token: TokenStorageService,
    private toast: CustomToastService,
    private tokenStorageService: TokenStorageService,
    private tenantService: TenantService,
    private userService: UserService,
    private utils: UTILS
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      let paramKeys = params.keys;
      let checkBasicParams = ["forceSignOut"].every((key:any) => paramKeys.includes(key));
      if(checkBasicParams) {
        if(params.get("forceSignOut") === "true") {
          this.tokenStorageService.logOut();
        } else {
          this.initDashboard();
        }
      } else {
        this.initDashboard();
      }
    })
  }

  initDashboard() {
    this.loadingContainer = true;
    let thread = [this.loadingTenants.asObservable(), this.loadingActiveUsers.asObservable()];
    this.isAdminRole = this.token.getRole()?.includes('NELSON_MANAGER') || false;
    this.isNotClientNonAdminRole = (this.token.getRole()?.includes('NELSON_MANAGER') || this.token.getRole()?.includes('CLIENT_ADMIN')) || false;
    this.showFirstNameCurrentUser();
    this.tenantService.loadTenants({ tenantids: this.isAdminRole ? '' : this.token.getTenantIds() }).subscribe(
      (data: any) => {
        this.tenants = data.tenants;
        this.environments = this.utils.getEnvironments(this.tenants, this.tokenStorageService.decodeJwtToken(this.tokenStorageService.getToken()!));
        this.environments.forEach((environment: any) => environment.issues = this.getEnviromnentIssue(environment));
        this.loadingTenants.next(true);
      },
      (errorResp: any) => {
        this.toast.error("Error fetching clients.", 'Error!');
        this.loadingTenants.next(true);
      }
    );
    this.userService.loadUsers({ includeenvironments: true, tenantids: this.isAdminRole ? '' : this.token.getTenantIds(), activeonly: true }).subscribe(
      (data: any) => {
        this.users = data.users;
        this.loadingActiveUsers.next(true);
      },
      (errResp: any) => {
        this.toast.error("Error fetching active users.", 'Error!');
        this.loadingActiveUsers.next(true);
      }
    );
    combineLatest(thread).subscribe((data) => {
      if (data.indexOf(false) < 0) {
        this.loadingContainer = false;
        this.updateActiveUsers();
      }
    });
  }

  showFirstNameCurrentUser() {
    let emailCurrentUser = this.tokenStorageService.getEmailCurrentUser();;
    let emailStr = (emailCurrentUser || '').split('@')[0];
    if (emailStr.indexOf(".") === -1) {
      // Ex: email: tttem@tma.com.vn -> Out put: tttem
      this.firstName = emailStr;
    } else {
      // Ex: email: tttem.tran@tma.com.vn -> Out put: tttem
      this.firstName = emailStr?.slice(0, emailStr.indexOf("."))
    }
  }

  chooseEnvironment(environment: any): void {
    // Allow User Management redirect to MUI 
    localStorage.setItem("isUserManagement", "false");
    if (this.utils.isLocalEnvironment()) {
      // Displaying local MUI in tenant
      window.location.href = `${environment.managementsitehost}/#/reservations?token=${this.tokenStorageService.getToken()}&refreshtoken=${this.tokenStorageService.getRefreshToken()}`;
      this.updateTenantName({name: "LOCALHOST"});
      return;
    }
    const tenant = this.utils.getTenantForEnvironment(this.tenants, environment);
    this.updateTenantName(tenant);
    window.location.href = `${environment.managementsitehost}/#/reservations?token=${this.tokenStorageService.getToken()}&refreshtoken=${this.tokenStorageService.getRefreshToken()}`;
  }

  updateTenantName(tenant: any) {
    CONSTANT.HOTEL_CHAIN_NAME = tenant.name;
  }

  updateActiveUsers() {
    if (this.environments) {
      this.environments.forEach((environment: any) => {
        environment.activeUsers = this.users.filter((user: any) => user.environmentids?.indexOf(environment.id) > -1 && user.roles != 'NELSON_MANAGER')?.length;
      });
    }
  }

  getEnviromnentIssue(environment: any) {
    return 0; //TODO: Implement issues functionality
  }

  determineTime() {
    const now = new Date();
    const hours = now.getHours();

    if (hours >= 5 && hours < 12) {
        return "Good morning";
    } else if (hours >= 12 && hours < 18) {
        return "Good afternoon";
    } else if (hours >= 18 && hours < 21) {
        return "Good evening";
    } else {
        return "Good night";
    }
  }
}

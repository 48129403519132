import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UserService } from 'src/app/services/user.service';
import { CustomModalComponent } from '../common/custom-modal/custom-modal.component';
import { ResultAlertType, LoadingAlertType } from '../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.sass']
})
export class ForgotComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private userService: UserService,
    private toast: CustomToastService,
  ) { }

  requestedUsername: string | null = null;
  loading: boolean = false;
  resultElements:ResultAlertType = {
    type: 'success',
    title: '',
    desc: ''
  }
  // Loading alert
  loadingElements:LoadingAlertType = {
    title: "",
    desc: undefined
  }

  // New custom modal
  @ViewChildren(CustomModalComponent) modalComponents!: QueryList<CustomModalComponent>;
  modalComponentList:CustomModalComponent[] = [];

  ngAfterViewInit(): void {
    this.modalComponentList = this.modalComponents.toArray();
  }

  ngOnInit(): void {
    if (this.tokenStorageService.getToken()) {
      this.router.navigateByUrl("/dashboard");
    }
  }

  forgotForm: any = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  openCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("open")
    } else {
      console.error("Invalid modal request!")
    }
  }
  closeCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("close")
    } else {
      console.error("Invalid modal request!")
    }
  }
  openResultAlert(type:string, title:string, desc:string) {
    let tmpResultElements:ResultAlertType = {
      type: type,
      title: title,
      desc: desc
    }
    this.resultElements = tmpResultElements;
    this.openCustomModal('resultAlert');
  }
  closeResultAlert() {
    this.closeCustomModal('resultAlert');
  }
  openLoadingAlert(title:string, desc?:string) {
    this.loadingElements = {
      title: title,
      desc: desc
    }
    this.openCustomModal('loadingAlert');
  }
  closeLoadingAlert() {
    this.closeCustomModal('loadingAlert');
  }

  validateForgotForm(): Boolean {
    let isErrorForm = false;
    // Check email 
    if (this.forgotForm.controls.email.status === "INVALID") {
      if (this.forgotForm.controls.email.errors.required) {
        this.toast.error("Email is required.", 'Error!');
        return isErrorForm;
      }
      if (this.forgotForm.controls.email.errors.email) {
        this.toast.error("Please enter valid email address.", 'Error!');
        return isErrorForm;
      }
    }
    return !isErrorForm;
  }
  validateResetPasswordForm(): Boolean {
    let isErrorForm = false;
    // Check email 
    if (this.resetPasswordForm.controls.code.status === "INVALID") {
      if (this.resetPasswordForm.controls.code.errors.required) {
        this.toast.error("Verification code is required.", 'Error!');
        return isErrorForm;
      }
      if (this.resetPasswordForm.controls.code.errors) {
        this.toast.error("Please, enter valid verification code.", 'Error!');
        return isErrorForm;
      }
    }
    if (this.resetPasswordForm.controls.newPassword.status === "INVALID") {
      if (this.resetPasswordForm.controls.newPassword.errors.required) {
        this.toast.error("Please enter a new password.", 'Error!');
        return isErrorForm;
      }
      if (this.resetPasswordForm.controls.newPassword.errors) {
        this.toast.error("Please enter a valid new password.", 'Error!');
        return isErrorForm;
      }
    }
    if (this.resetPasswordForm.value.newPassword != this.resetPasswordForm.value.confirmNewPassword) {
      this.toast.error("New password and confirm password do not match.", 'Error!');
      return false;
    }
    return !isErrorForm;
  }

  resetPasswordForm: any = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)]),
    newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)]),
    confirmNewPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)])
  });

  submitForgotForm() {
    if (this.validateForgotForm()) {
      this.loading = true;
      this.openLoadingAlert("Sending verification code...");
      const username = this.forgotForm.value.email;
      this.sendVerificationCode(username).subscribe(
        () => {
          this.closeLoadingAlert();
          this.requestedUsername = username;
          this.loading = false;
          this.openCustomModal("resetPasswordModal")
        },
        (error: any) => {
          this.closeLoadingAlert();
          const message = error.error.message;
          this.openResultAlert("error", "Error", message);
          this.loading = false;
        }
      );
    }
  }

  resendVerificationCode() {
    if (this.loading) return;
    this.closeCustomModal("resetPasswordModal")
    this.openLoadingAlert("Resending verification code...");
    this.loading = true;
    this.sendVerificationCode(this.forgotForm.value.email).subscribe(
      () => {
        this.closeLoadingAlert();
        this.openCustomModal("resetPasswordModal")
        this.loading = false;
      },
      (error: any) => {
        this.closeLoadingAlert();
        const message = error.error.message;
        this.openCustomModal("resetPasswordModal")
        this.openResultAlert("error", "Error", message);
        this.loading = false;
      }
    );
  }

  private sendVerificationCode(username: string) {
    return this.userService.requestVerificationCode(username);
  }

  submitResetPasswordForm() {
    if (this.validateResetPasswordForm() && this.requestedUsername) {
      this.closeCustomModal("resetPasswordModal")
      this.openLoadingAlert("Resetting password...");
      this.loading = true;
      this.userService.forgotResetPassword(this.resetPasswordForm.value.code, this.requestedUsername, this.resetPasswordForm.value.newPassword).subscribe(
        (data) => {
          this.closeLoadingAlert();
          this.openCustomModal("resetSuccessAlert")
          setTimeout(() => {
            this.router.navigate(['/login'])
          }, 5000);
        },
        (error) => {
          this.closeLoadingAlert();
          const message = error.error.message;
          this.openCustomModal("resetPasswordModal")
          this.openResultAlert("error", "Error", message);
          this.loading = false;
        }
      );
    }
  }

}

import { environment } from "src/environments/environment";

export type RuleValueConvertType = {
  ruleCodeId: string,
  ruleName: string,
  ruleBaseValueName: string
}

export class CONSTANT {
  public static DATE_FORMAT = 'YYYY-MM-DD';
  public static ONE_DAY_MS = 86400000;
  public static EN_US = "en-us";
  public static datepickerConfig = {
    containerClass: 'theme-red',
    isAnimated: true,
    dateInputFormat: 'DD.MM.YYYY'
  }
  public static LIMITOFSEARCH = 30;
  public static LIMIT_SEARCH_RESERVATION = 10;
  public static SALO_URL = "https://test-salo.bookn.io";
  public static PATH_NAME_BOOKING = "/en/booking/#";
  public static LOCALHOST_URL = "http://" + environment.buiLocalUrl + CONSTANT.PATH_NAME_BOOKING;

  public static PRICING_RULE_NAME:RuleValueConvertType[] = [
    {
      ruleCodeId: "SINGLE",
      ruleName: "Single",
      ruleBaseValueName: "Standard"
    },
    {
      ruleCodeId: "MEMBER",
      ruleName: "Member",
      ruleBaseValueName: "STG&SGL"
    },
    {
      ruleCodeId: "BOOKINGCOM",
      ruleName: "Booking.com",
      ruleBaseValueName: "selectedRoom"
    },
    {
      ruleCodeId: "EXPEDIA",
      ruleName: "Expedia",
      ruleBaseValueName: "selectedRoom"
    }
  ]
  
  //GROUP-BOOKING
  public static GROUP_BOOKING = "/group-booking";
  public static GROUP_BOOKING_LOCALHOST = CONSTANT.LOCALHOST_URL + CONSTANT.GROUP_BOOKING;
  public static GROUP_BOOKING_URL = CONSTANT.PATH_NAME_BOOKING + CONSTANT.GROUP_BOOKING;

  //MANAGE-BOOKING
  public static MANAGE_BOOKING = "/manage-booking";
  public static MANAGE_BOOKING_LOCALHOST = CONSTANT.LOCALHOST_URL + CONSTANT.MANAGE_BOOKING;
  public static MANAGE_BOOKING_URL = CONSTANT.PATH_NAME_BOOKING + CONSTANT.MANAGE_BOOKING;

  //CONFIRMATION PAGE
  public static CONFIRMATION = "/confirmation";
  public static CONFIRMATION_LOCALHOST = CONSTANT.LOCALHOST_URL + CONSTANT.CONFIRMATION;
  public static CONFIRMATION_URL = CONSTANT.PATH_NAME_BOOKING + CONSTANT.CONFIRMATION;

  //CUSTOM-SELECT-ROOM
  public static CUSTOM_SELECT_ROOM = "/customSelectRoom";
  public static CUSTOM_SELECT_ROOM_LOCALHOST = CONSTANT.LOCALHOST_URL + CONSTANT.CUSTOM_SELECT_ROOM;
  public static CUSTOM_SELECT_ROOM_URL = CONSTANT.PATH_NAME_BOOKING + CONSTANT.CUSTOM_SELECT_ROOM;


  public static ROLE: any = "";
  public static SITEHOST = '';
  public static URL_ORIGIN = '';
  public static NORMAL_CHECK_IN = 16;
  public static NORMAL_CHECK_OUT = 12;
  public static REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static WEEKDAYS = [
    { code: "MON", name: 'Monday', selected: true },
    { code: "TUE", name: 'Tueday', selected: true },
    { code: "WED", name: 'Wednesday', selected: true },
    { code: "THU", name: 'Thurday', selected: true },
    { code: "FRI", name: 'Friday', selected: true },
    { code: "SAT", name: 'Saturday', selected: true },
    { code: "SUN", name: 'Sunday', selected: true }
  ];
  public static CAPACITIES = [2,3,4];
  public static NEW_SESSION = "newSession=true";
  public static WEEKDAYS_UPDATE = [{
    code: "MON",
    selected: true
    },
    {
      code: "TUE",
      selected: true
    },
    {
      code: "WED",
      selected: true
    },
    {
      code: "THU",
      selected: true
    },
    {
      code: "FRI",
      selected: true
    },
    {
      code: "SAT",
      selected: true
    },
    {
      code: "SUN",
      selected: true
    }
  ];
  public static WEEKDAYS_TEMP = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  public static ALLOTMENT_TYPES = [
    {name: "Soft", value: 'SOFT'},
    {name: "Hard", value: 'HARD'}
  ];
  public static REPORT_TYPES = [
    {
      label: 'Breakfast Report',
      urlParam: 'breakfast',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE']
    },
    {
      label: 'Cleaning Report',
      urlParam: 'cleaning-list',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE', 'CLEANER']
    },
    {
      label: 'Daily Availability Report',
      urlParam: 'daily-availability',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE', 'REVENUE_MANAGER']
    },
    {
      label: 'Guests Report',
      urlParam: 'guests',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE']
    },
    {
      label: 'Guests Mobile Numbers Report',
      urlParam: 'guests-mobile',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE']
    },
    {
      label: 'On The Books Report',
      urlParam: 'on-the-books',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'On The Books by Reservation Report',
      urlParam: 'on-the-books-by-reservation',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Revenue by Customer Segment',
      urlParam: 'revenue-by-customer',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Revenue Report',
      urlParam: 'new-revenue',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Sales by Channel Report',
      urlParam: 'sales',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
	{
      label: 'Sales Report by Payment type',
      urlParam: 'payments',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Sales Summary Report',
      urlParam: 'financial-summary',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Sales Forecast - Daily',
      urlParam: 'sales-forecast-daily',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Sales Forecast per Segment - Daily',
      urlParam: 'sales-forecast-per-segment-daily',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER', 'ACCOUNTING']
    },
    {
      label: 'Unpaid Expedia Reservations Report',
      urlParam: 'unpaid-expedia-reservations',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN']
    },
    {
      label: 'Unpaid Reservations Report',
      urlParam: 'unpaid-reservations',
      requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN']
    }
  ];
  public static CLEANING_REPORT_FOR_CLEARNER_ACCOUNT = [
    {
      label: 'Cleaning Report',
      urlParam: 'cleaning-list',
    }
  ];
  public static TIMEZONE_HELSINKI = "Europe/Helsinki";
  public static TIME_SECIF = [
    { name: "SameDayECIFlex_15", friendlyName: "Arrive at 15:00" },
    { name: "SameDayECIFlex_14", friendlyName: "Arrive at 14:00" },
    { name: "SameDayECIFlex_13", friendlyName: "Arrive at 13:00" },
    { name: "SameDayECIFlex_12", friendlyName: "Arrive at 12:00" },
    { name: "SameDayECIFlex_11", friendlyName: "Arrive at 11:00" },
    { name: "SameDayECIFlex_10", friendlyName: "Arrive at 10:00" },
    { name: "SameDayECIFlex_9", friendlyName: "Arrive at 09:00" },
    { name: "SameDayECIFlex_8", friendlyName: "Arrive at 08:00" },
    { name: "SameDayECIFlex_7", friendlyName: "Arrive at 07:00" },
    { name: "SameDayECIFlex_6", friendlyName: "Arrive at 06:00" },
  ];
  public static OPTIONAL_FILTER_VOUCHER = [
    {
      label: 'Discount Voucher',
      value: 'DISCOUNT_VOUCHER',
      voucherType: 'main',
      checked: true,
      enabled: true
    },

    {
      label: 'Booking period',
      value: 'BOOKING_PERIOD',
      voucherType: 'DISCOUNT_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Reservation period',
      value: 'RESERVATION_PERIOD',
      voucherType: 'DISCOUNT_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Voucher name',
      value: 'VOUCHER_NAME',
      voucherType: 'DISCOUNT_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Voucher description',
      value: 'VOUCHER_DESCRIPTION',
      voucherType: 'DISCOUNT_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Monetary Voucher',
      value: 'MONETARY_VOUCHER',
      voucherType: 'main',
      checked: true,
      enabled: true
    },
    {
      label: 'Monetary voucher code',
      value: 'VOUCHER_CODE',
      voucherType: 'MONETARY_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Customer',
      value: 'CUSTOMER_NAME',
      voucherType: 'MONETARY_VOUCHER',
      checked: true,
      enabled: true
    },
    {
      label: 'Email',
      value: 'EMAIL',
      voucherType: 'MONETARY_VOUCHER',
      checked: true,
      enabled: true
    },
  ];
  public static CHANNEL_BOOKING = [
    {'value': 'NELSON', 'label': 'Web'},
    {'value': 'BOOKINGCOM', 'label': 'Booking.com'},
    {'value': 'EXPEDIA', 'label': 'Expedia'},
    {'value': 'LEGACY', 'label': 'Legacy'},
    {'value': 'MOBILEAPP', 'label': 'MobileApp'},
  ];
  public static OTA_CHANNEL = ["BOOKINGCOM", "EXPEDIA"];
  public static FILTER_RESERVATIONS = [
    {
      label: 'Full name',
      value: 'FULL_NAME',
      checked: true,
    },
    {
      label: 'First name',
      value: 'FIRST_NAME',
      checked: true,
    },
    {
      label: 'Last name',
      value: 'LAST_NAME',
      checked: true,
    },
    {
      label: 'Company name',
      value: 'ORGANIZATION',
      checked: true,
    },
    {
      label: 'Email',
      value: 'EMAIL',
      checked: true,
    },
    {
      label: 'Mobile number',
      value: 'MOBILE_NUMBER',
      checked: true,
    },
    {
      label: 'Reservation number',
      value: 'RESERVATION_NUMBER',
      checked: true,
    },
    {
      label: 'External channel reservation number',
      value: 'EXTERNAL_CHANNEL_RESERVATION_ID',
      checked: true,
    },
    {
      label: 'SSN',
      value: 'SSN',
      checked: false,
    },
    {
      label: 'Room number',
      value: 'ROOM_NUMBER',
      checked: false,
    },
    {
      label: 'Discount voucher',
      value: 'DISCOUNT_VOUCHER',
      checked: false,
    },
    {
      label: 'Monetary voucher',
      value: 'MONETARY_VOUCHER',
      checked: false,
    }
  ];
  public static FILTER_INVOICE_MANUAL = [
    {
      label: 'Full name',
      value: 'FULL_NAME',
      checked: true,
    },
    {
      label: 'First name',
      value: 'FIRST_NAME',
      checked: true,
    },
    {
      label: 'Last name',
      value: 'LAST_NAME',
      checked: true,
    },
    {
      label: 'Organization',
      value: 'ORGANIZATION',
      checked: true,
    },
    {
      label: 'Email',
      value: 'EMAIL',
      checked: true,
    },
    {
      label: 'Discount voucher',
      value: 'DISCOUNT_VOUCHER',
      checked: false,
    }
  ];
  
public static PAGE_REQUIRED_ROLES = [
  {
    pageName: 'reservations',
    friendlyName: 'Reservations',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'REVENUE_MANAGER',
      'ACCOUNTING',
    ],
  },
  {
    pageName: 'usage',
    friendlyName: 'Usage',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'CLEANER',
      'REVENUE_MANAGER',
    ],
  },
  {
    pageName: 'maintenance',
    friendlyName: 'Maintenance',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'CLEANER',
    ],
  },
  {
    pageName: 'reports',
    friendlyName: 'Reports',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'CLEANER',
      'REVENUE_MANAGER',
      'ACCOUNTING',
    ],
  },
  {
    pageName: 'customers',
    friendlyName: 'Customers',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
    ],
  },
  {
    pageName: 'pricing',
    friendlyName: 'Pricing',
    requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'REVENUE_MANAGER'],
  },
  {
    pageName: 'allotments',
    friendlyName: 'Allotments',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'REVENUE_MANAGER',
    ],
  },
  {
    pageName: 'voucher',
    friendlyName: 'Vouchers',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'REVENUE_MANAGER',
    ],
  },
  {
    pageName: 'breakfast',
    friendlyName: 'Breakfast',
    requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE'],
  },
  {
    pageName: 'hotels',
    friendlyName: 'Settings',
    requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN'],
  },
  {
    pageName: 'locks',
    friendlyName: 'Locks',
    requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN', 'CUSTOMER_SERVICE'],
  },
  {
    pageName: 'internal-tools-mui',
    friendlyName: 'Internal tools',
    requiredRoles: ['NELSON_MANAGER'],
  }
];
  public static SEARCH_OBJECT = [
    {'value': 'CUSTOMER', 'label': 'Customer', 'checked': true},
    {'value': 'GUEST', 'label': 'Guest', 'checked': false},
  ];
  public static HOTEL_CHAIN_NAME = "OmenaHotels";
  public static EXTERNAL_CHANNEL_BOOKING = [
    {'value': 'BOOKINGCOM', 'label': 'Booking.com'},
    {'value': 'EXPEDIA', 'label': 'Expedia'}
  ];
  public static LOCALE_LANGUAGES = [
    { name: "Finnish", locale: "fi", disabled: false},
    { name: "English", locale: "en", disabled: false},
    { name: "Swedish", locale: "sv", disabled: false}
  ];
  public static SUPPORTED_LANGUAGES = [
    { label: "English", value: "en"},
    { label: "Finnish", value: "fi"},
    { label: "Swedish", value: "sv"},
    { label: "Russian", value: "ru"}
  ];
  public static DELIVERY_METHODS = [
    { name: "Electronic invoice", value: "finvoice"},
    { name: "Email", value: "email"},
  ];
  public static TERMS_OF_PAYMENT = [
    { name: "Immediately", value: 0},
    { name: "7 days net", value: 7},
    { name: "14 days net", value: 14},
    { name: "21 days net", value: 21},
    { name: "30 days net", value: 30}
   ];
  public static E_INVOICE_OPERATOR = [
    { name: "Aktia Pankki Oyj", swiftCode: "HELSFIHH"},
    { name: "Apix Messaging Oy", swiftCode: "003723327487"},
    { name: "Basware Oyj", swiftCode: "BAWCFI22"},
    { name: "CGI Suomi Oy", swiftCode: "003703575029"},
    { name: "Comarch", swiftCode: "5909000716438"},
    { name: "Crediflow Ab", swiftCode: "CREDIFLOW"},
    { name: "Danske Bank Oyj", swiftCode: "DABAFIHH"},
    { name: "DNB Bank ASA", swiftCode: "DNBAFIHX"},
    { name: "Handelsbanken", swiftCode: "HANDFIHH"},
    { name: "HighJump AS", swiftCode: "885790000000418"},
    { name: "InExchange Factorum AB", swiftCode: "INEXCHANGE"},
    { name: "Lexmark Expert Systems AB", swiftCode: "EXPSYS"},
    { name: "Maventa", swiftCode: "003721291126"},
    { name: "Netbox Finland Oy", swiftCode: "003726044706"},
    { name: "Nordea", swiftCode: "NDEAFIHH"},
    { name: "OP-Pohjola-ryhmä", swiftCode: "OKOYFIHH"},
    { name: "OpenText / Liaison Technologies Oy", swiftCode: "003708599126"},
    { name: "OpusCapita Group Oy / Itella", swiftCode: "003710948874"},
    { name: "OpusCapita Solutions Oy", swiftCode: "E204503"},
    { name: "Pagero", swiftCode: "003723609900"},
    { name: "Pagero", swiftCode: "PAGERO"},
    { name: "Paikallisosuuspankit", swiftCode: "POPFFI22"},
    { name: "Palette Software", swiftCode: "PALETTE"},
    { name: "PEPPOL BIS Billing 3.0", swiftCode: "PEPPOL"},
    { name: "Posti Messaging Oy", swiftCode: "003710948874"},
    { name: "PostNord Strålfors Oy", swiftCode: "003701150617"},
    { name: "Ropo Capital Oy / Enfo Zender", swiftCode: "003714377140"},
    { name: "S-Pankki Oy", swiftCode: "SBANFIHH"},
    { name: "Säästöpankit", swiftCode: "ITELFIHH"},
    { name: "Telia Finland Oyj", swiftCode: "003703575029"},
    { name: "TietoEVRY Oyj", swiftCode: "003701011385"},
    { name: "Tradeshift Ab", swiftCode: "885060259470028"},
    { name: "Åland Post Ab", swiftCode: "003722207029"},
    { name: "Ålandsbanken", swiftCode: "AABAFI22"}
  ];

  public static FILTER_LOCK = [
    {
      label: 'Door code',
      value: 'DOOR_CODE',
      checked: true,
    },
    {
      label: 'Name',
      value: 'DESCRIPTION',
      checked: false,
    },
    {
      label: 'Reservation',
      value: 'RESERVATION_CODE',
      checked: false,
    }
  ];

  public static FENNOA_INVOICE_RESERVATION_TYPE = [
    { name: "-", value: "NONE", productFennoaName: "None"},
    { name: "Balance", value: "FULL", productFennoaName: "Full"},
    { name: "Advance (VAT 10%)", value: "ADVANCE", productFennoaName: "Advance Payment (VAT 10%)"},
    { name: "Custom", value: "CUSTOM", productFennoaName: "Custom"},
    { name: "Credit invoice", value: "CREDIT_INVOICE", productFennoaName: "Credit invoice"},
  ];

  // AUTH-SERVICE
  public static AUTH_API = '/api/user/login';
  public static CONFIRM_USER_API = '/api/user/confirmuser';
  public static NEW_PASSWORD_REQUIRED_CHALLENGE = 'NEW_PASSWORD_REQUIRED';

  // USER-SERVICE
  public static LIST_USERS_API = '/api/user/listusers';
  public static USER_API = '/api/user/user';
  public static USER_ROLES_API = '/api/user/roles';
  public static RESET_PASSWORD_API = '/api/user/resetpassword';
  public static CHANGE_PASSWORD_API = '/api/user/changeuserpassword';
  public static FORGOT_PASSWORD_API = '/api/user/forgotpassword';
  public static CONFIRM_FORGOT_PASSWORD_API = '/api/user/confirmforgotpassword';
}
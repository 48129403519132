import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { TenantService } from 'src/app/services/tenant.service';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import { CONSTANT } from 'src/app/helpers/constants';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UTILS } from 'src/app/helpers/utils';
import { AnimationOptions } from 'ngx-lottie';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingAlertType, MenuType, ResultAlertType } from '../common/dropdown-menu/dropdown-menu.component';
import { CustomModalComponent } from '../common/custom-modal/custom-modal.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.sass', '../../../styles.sass']
})
export class UserComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  isAdminRole = true;
  allRoles: any = [];

  allTenants: any = [];
  tenantList:MenuType[] = [];
  selectedTenant?:MenuType;

  searchResult: any = [];
  allEnviroments: any[] = [];
  lastSearchEvaluatedId: string = '';
  selectedRoleLabel = 'All';
  selectedRole: any[] = [];
  selectedUser: any = {};
  loadingFromScratch:boolean = true;
  loadingContainer: boolean = true;
  submitted = false;
  isValidAddForm = false;
  loadUserLimit = CONSTANT.LIMITOFSEARCH;
  numberOfClickShowMore = 1;
  updatingPassword = false;
  userForm = new UntypedFormGroup({
    email: new UntypedFormControl(""),
    allroles: new UntypedFormArray([]),
  });
  editForm = new UntypedFormGroup({
    status: new UntypedFormControl('', []),
    roles: new UntypedFormControl('', []),
  });
  addForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(CONSTANT.REGEX_EMAIL),
    ]),
    fullname: new UntypedFormControl('', [
      Validators.required,
    ]),
  });
  resetPasswordForm: any = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required]),
    newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)]),
    confirmNewPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)])
  });
  loadingTenant: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingRole: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingUsers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  selectedEnvironment:string[] = [];
  selectedUserRole:string = "";
  selectedHotels:string[] = [];

  resultElements:ResultAlertType = {
    type: 'success',
    title: '',
    desc: ''
  }
  // Loading alert
  loadingElements:LoadingAlertType = {
    title: "",
    desc: undefined
  }

  // New custom modal
  @ViewChildren(CustomModalComponent) modalComponents!: QueryList<CustomModalComponent>;
  modalComponentList:CustomModalComponent[] = [];

  ngAfterViewInit(): void {
    this.modalComponentList = this.modalComponents.toArray();
  }

  openCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("open")
    } else {
      console.error("Invalid modal request!")
    }
  }
  closeCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("close")
    } else {
      console.error("Invalid modal request!")
    }
  }
  openResultAlert(type:string, title:string, desc:string) {
    let tmpResultElements:ResultAlertType = {
      type: type,
      title: title,
      desc: desc
    }
    this.resultElements = tmpResultElements;
    this.openCustomModal('resultAlert');
  }
  closeResultAlert() {
    this.closeCustomModal('resultAlert');
  }
  openLoadingAlert(title:string, desc?:string) {
    this.loadingElements = {
      title: title,
      desc: desc
    }
    this.openCustomModal('loadingAlert');
  }
  closeLoadingAlert() {
    this.closeCustomModal('loadingAlert');
  }

  // Modal
  modalRef!: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private tenantService: TenantService,
    private toast: CustomToastService,
    private modalService: BsModalService,
    private token: TokenStorageService,
    private utils: UTILS
  ) { }
  get f() { return this.userForm.controls; };
  get getEditForm() { return this.editForm.controls; };
  get getAddForm() { return this.addForm.controls; };
  get rolesFormArray() {
    return this.userForm.controls.allroles as UntypedFormArray;
  };
  ngOnInit(): void {
    this.isAdminRole = this.token.getRole()?.includes('NELSON_MANAGER') || false;
    let thread = [this.loadingRole.asObservable(), this.loadingTenant.asObservable(), this.loadingUsers.asObservable()];
    const loadTenantsService = this.tenantService.loadTenants({ tenantids: this.isAdminRole ? '' : this.token.getTenantIds() });
    loadTenantsService.subscribe(
      (data: any) => {
        this.allTenants = data.tenants;
        let tmpTenantList:MenuType[] = [];
        data.tenants.forEach((item:any) => {
          tmpTenantList.push({
            label: item.tenantname,
            value: item.id,
            disabled: false
          })
        })
        this.tenantList = tmpTenantList;
        this.selectTenant(tmpTenantList[0])
        
        const tmpAllEnviroments = this.utils.getEnvironments(this.allTenants, this.token.decodeJwtToken(this.token.getToken()!));
        this.allEnviroments = tmpAllEnviroments;
        this.clearCheckbox();

        this.loadingTenant.next(true);
      },
      (errorResp: any) => {
        this.toast.error("Error loading clients.", 'Error!');
        this.loadingTenant.next(true);
      });

    this.userService.loadAllRoles().subscribe(
      (data: any) => {
        this.allRoles = data.roles.filter((role: any) => this.isAdminRole || role.id != "NELSON_MANAGER");
        this.addRolesCheckboxes();
        this.loadingRole.next(true);
      },
      (errorResp: any) => {
        this.addRolesCheckboxes();
        this.loadingRole.next(true);
      }
    );
    loadTenantsService.subscribe((data: any) => {
      this.loadUsers();
    })
    combineLatest(thread).subscribe((data) => {
      this.loadingContainer = false;
    });
    setTimeout(() => {
      this.checkParam();
    }, 500);
  }
  checkParam() {
    this.route.queryParamMap.subscribe(params => {
      let paramKeys = params.keys;
      let checkBasicParams = ["editCurrentUser"].every((key: any) => paramKeys.includes(key));
      if (checkBasicParams) {
        const getToken = this.token.getToken();
        if (params.get('editCurrentUser') === "true" && getToken) {
          this.openLoadingAlert("Loading user...")
          try {
            const decodedToken: any = this.token.decodeJwtToken(getToken);
            this.userService.loadUserDetails({
              userid: decodedToken["cognito:username"]
            }).subscribe(
              (data: any) => {
                this.selectedUser = data.user;
                this.getEditForm.status.setValue(this.selectedUser.enabled);
                this.preSelectEnvironmentFromUser();
                this.closeLoadingAlert();
                this.openCustomModal("editUserModal");
              }
            ),
              (errorResp: any) => {
                this.closeLoadingAlert();
                this.openResultAlert("error", "Error", "Unable to load requested user details");
                this.loadingFromScratch = false;
                this.loadingContainer = false;
              }
          } catch (error) {
            this.closeLoadingAlert();
            this.openResultAlert("error", "Error", "Unable to load requested user details");
          }
        }
      }
    })
  }
  checkEditUserIsCurrent() {
    const getToken = this.token.getToken();
    if(getToken) {
      try {
        const decodedToken: any = this.token.decodeJwtToken(getToken);
        return decodedToken.email === this.selectedUser.email;
      } catch (error) {
        return false;
      }
    }
  }
  
  selectTenant(item:MenuType) {
    this.selectedTenant = item;
  }
  toggleEnvironment(id:string) {
    if(this.selectedEnvironment.includes(id)) {
      const findEnvIndex = this.selectedEnvironment.findIndex(env => env === id);
      if(findEnvIndex > -1) {
        this.selectedEnvironment.splice(findEnvIndex, 1);
      }
    } else {
      this.selectedEnvironment.push(id);
    }
    this.checkInvalidAddForm();
  }
  clearCheckbox() {
    this.selectedEnvironment = [];
    this.selectedHotels = [];
    this.selectedUserRole = "";
    this.checkInvalidAddForm();
  }
  preSelectEnvironmentFromUser() {
    if(this.selectedUser) {
      let tmpSelectedEnv:string[] = [];
      let tmpSelectedHotels:string[] = [];
      this.allEnviroments.forEach((env:any) => {
        if(this.selectedUser.environmentids.includes(env.id)) {
          tmpSelectedEnv.push(env.id);
          if(this.selectedUser.hotelids) {
            env.hotels.forEach((hotel:any) => {
              if(this.selectedUser.hotelids.includes(hotel.id)) {
                tmpSelectedHotels.push(hotel.id);
              }
            })
          }
        }
      })
      this.selectedEnvironment = tmpSelectedEnv;
      this.selectedHotels = tmpSelectedHotels;
    }
    this.checkInvalidAddForm();
  }
  toggleHotel(id:string) {
    if(this.selectedHotels.includes(id)) {
      const findEnvIndex = this.selectedHotels.findIndex(env => env === id);
      if(findEnvIndex > -1) {
        this.selectedHotels.splice(findEnvIndex, 1);
      } else {
        this.selectedHotels.push(id);
      }
    }
  }

  selectUserRole(role:string) {
    this.selectedUserRole = role;
    this.checkInvalidAddForm();
  }

  searchUser() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    this.loadingFromScratch = true;
    this.numberOfClickShowMore = 1;
    this.lastSearchEvaluatedId = "";
    this.loadUsers();
  }
  convertRoleTermToString(roles: any[]) {
    return roles.reduce((a, c) => {
      a.push(this.allRoles.filter((role: any) => role.id == c)[0].roleName);
      return a;
    }, []).join();
  }
  selectSearchRole(role: any) {
    let hasRole = this.selectedRole.includes(role.id);
    if (hasRole) {
      this.selectedRole = this.selectedRole.filter(r => r != role.id);
    }
    else {
      this.selectedRole.push(role.id);
    }
    if (this.selectedRole.length && this.selectedRole.length < this.allRoles.length) {
      this.selectedRoleLabel = this.convertRoleTermToString(this.selectedRole);
    }
    else {
      this.selectedRoleLabel = 'All';
    }
  }
  loadUsers() {
    if(this.selectedTenant) {
      let request: any = {
        tenantid: this.selectedTenant.value,
        userroleids: this.selectedRole.join(','),
        email: this.f.email.value,
        limit: this.loadUserLimit,
      };
      if (this.lastSearchEvaluatedId != '') {
        request.lastevaluatedid = this.lastSearchEvaluatedId;
      }
      this.userService.loadUsers(request).subscribe(
        (data: any) => {
          if (this.lastSearchEvaluatedId === "") this.searchResult = [];
          this.searchResult.push(...data.users.filter((user: any) => this.isAdminRole || user.roles != "NELSON_MANAGER"));
          this.loadingFromScratch = false;
          this.loadingContainer = false;
          this.loadingUsers.next(true);
          this.lastSearchEvaluatedId = data.lastEvaluatedId || '';
          this.loadUserLimit = CONSTANT.LIMITOFSEARCH;
        },
        (errorResp: any) => {
          this.toast.error("Unable to fetch users.", 'Error!');
          this.searchResult = [];
          this.loadingFromScratch = false;
          this.loadingContainer = false;
          this.loadingUsers.next(true);
          this.loadUserLimit = CONSTANT.LIMITOFSEARCH;
        }
      );
    }
  }
  editUser(result?: any) {
    if (!this.selectedEnvironment.length) {
      this.toast.error("Please select at least one environment.", 'Error!');
      this.loadingContainer = false;
      return;
    }
    this.closeCustomModal("editUserModal");
    this.openLoadingAlert("Updating user account...");
    this.selectedUser.environmentids = this.selectedEnvironment.join();
    this.selectedUser.tenantids = this.utils.getTenantsForEnvironments(this.allTenants, this.selectedEnvironment);
    this.loadingContainer = true;
    this.selectedUser.username = this.selectedUser.email;
    this.selectedUser.fullname = this.selectedUser.name;
    this.selectedUser.disabled = !this.selectedUser.enabled;
    this.selectedUser.resendcredentials = !this.selectedUser.confirmed;
    this.selectedUser.hotelids = this.selectedHotels.join(',');
    this.selectedUser.roles = this.selectedUserRole;
    this.userService.updateUser(this.selectedUser).subscribe(
      (data: any) => {
        this.lastSearchEvaluatedId = "";
        this.loadUserLimit = this.numberOfClickShowMore * CONSTANT.LIMITOFSEARCH;
        this.loadUsers();
        this.closeLoadingAlert();
        this.openResultAlert("success", "Success", "User account has been updated.")
      },
      (errorResp: any) => {
        this.closeLoadingAlert();
        this.openCustomModal("editUserModal");
        this.openResultAlert("error", "Error", "An error occurred while updating user account, please try again later.")
        this.loadingContainer = false;
      });
  }
  addUser(result?: any) {
    this.closeCustomModal("addUserModal");
    this.openLoadingAlert("Creating account...");
    this.loadingContainer = true;
    this.userService.updateUser({
      "email": this.addForm.value.email,
      "username": this.addForm.value.email,
      "fullname": this.addForm.value.fullname,
      "environmentids": this.selectedEnvironment.join(),
      "tenantids": this.utils.getTenantsForEnvironments(this.allTenants, this.selectedEnvironment),
      "roles": this.selectedUserRole,
      "hotelids": this.selectedHotels.join()
    }).subscribe(
      (data: any) => {
        this.lastSearchEvaluatedId = "";
        this.loadUsers();
        this.closeLoadingAlert();
        this.openResultAlert("success", "Success", "Account has been created and a welcome email has been sent.")
      },
      (errorResp: any) => {
        this.closeLoadingAlert();
        this.openCustomModal("addUserModal");
        this.openResultAlert("error", "Error", "An error occurred while creating user account, please try again later.")
        this.loadingContainer = false;
      }
    );
  }
  openConfirmResetPassword() {
    this.closeCustomModal("editUserModal");
    this.openCustomModal("confirmResetPasswordAlert");
  }
  resetPassword(result?: any) {
    this.closeCustomModal("confirmResetPasswordAlert");
    this.openLoadingAlert("Resetting password...");
    this.userService.resetUserPassword({
      username: this.selectedUser.email
    }).subscribe(
      (data: any) => {
        this.closeLoadingAlert();
        this.openCustomModal("editUserModal");
        this.openResultAlert("success", "Success", "Account password has been reset to default");
      },
      (errResp: any) => {
        this.closeLoadingAlert();
        this.openCustomModal("editUserModal");
        this.openResultAlert("error", "Error", "Unable to reset account password");
      }
    );
  }
  openUserAddModal() {
    this.addForm.patchValue({
      email: '',
      fullname: '',
      roles: '',
    });
    this.clearCheckbox();
    this.openCustomModal("addUserModal");
  }
  openEditModal(result: any) {
    this.openLoadingAlert("Loading user...")
    this.selectedUser = JSON.parse(JSON.stringify(result));
    this.userService.loadUserDetails({
      userid: this.selectedUser.id
    }).subscribe(
      (data: any) => {
        this.closeLoadingAlert();
        this.selectedUser = data.user;
        this.getEditForm.status.setValue(this.selectedUser.enabled);
        this.preSelectEnvironmentFromUser();
        this.selectUserRole(this.selectedUser.roles);
        this.openCustomModal("editUserModal");
      }
    ),
      (errorResp: any) => {
        this.closeLoadingAlert();
        this.openResultAlert("error", "Error", "An error occurred while loading user details, please try again later.");
        this.loadingContainer = false;
      }
  }
  closeModal() {
    this.modalRef.hide();
  }
  addRolesCheckboxes() {
    this.allRoles.forEach((e: any) => {
      return this.rolesFormArray.push(new UntypedFormControl(false));
    });
  }
  checkInvalidAddForm() {
    if (this.selectedEnvironment.length > 0 && this.selectedUserRole !== "")
      return this.isValidAddForm = true
    return this.isValidAddForm = false;
  }
  createAvatar(fullName: string) {
    let allName = fullName.split(" ");
    let finalText = "";
    allName.forEach((text, index) => {
      if(index < 2) {
        finalText += text.charAt(0)
      }
    })
    return finalText;
  }
  getUserRoleName(roleId: string) {
    return this.allRoles.filter((role: any) => role.id == roleId)[0]?.roleName
  }
  handleClickShowMore() {
    this.numberOfClickShowMore++;
    this.loadingContainer = true;
    this.loadUsers();
  }

  validateResetPasswordForm(): Boolean {
    let isErrorForm = false;
    if (this.resetPasswordForm.controls.password.status === "INVALID") {
      if (this.resetPasswordForm.controls.password.errors.required) {
        this.toast.error("Current password is required.", 'Error!');
        return isErrorForm;
      }
      if (this.resetPasswordForm.controls.password.errors) {
        this.toast.error("Please enter a valid current password.", 'Error!');
        return isErrorForm;
      }
    }
    if (this.resetPasswordForm.controls.newPassword.status === "INVALID") {
      if (this.resetPasswordForm.controls.newPassword.errors.required) {
        this.toast.error("Please enter a new password.", 'Error!');
        return isErrorForm;
      }
      if (this.resetPasswordForm.controls.newPassword.errors) {
        this.toast.error("Please enter a valid new password.", 'Error!');
        return isErrorForm;
      }
    }
    if (this.resetPasswordForm.value.newPassword != this.resetPasswordForm.value.confirmNewPassword) {
      this.toast.error("New password and confirm password do not match.", 'Error!');
      return false;
    }
    return !isErrorForm;
  }

  submitResetPassword() {
    if (!this.validateResetPasswordForm()) {
      return;
    }
    this.updatingPassword = true;
    const getToken = this.token.getToken();
    if(getToken) {
      this.closeCustomModal("editUserModal");
      this.openLoadingAlert("Updating password...");
      try {
        const decodedToken: any = this.token.decodeJwtToken(getToken);
        let refreshToken = this.token.getRefreshToken();
        if(refreshToken && decodedToken) {
          this.authService.loginWithAccess(decodedToken.sub, refreshToken).subscribe(
            (data) => {
              this.userService.updatePassword(data.accesstoken, this.resetPasswordForm.value.password, this.resetPasswordForm.value.newPassword).subscribe(
                (data) => {
                  this.resetPasswordForm.patchValue({
                    password: "",
                    newPassword: "",
                    confirmNewPassword: ""
                  });
                  this.updatingPassword = false;
                  this.closeLoadingAlert();
                  this.openCustomModal("editUserModal");
                  this.openResultAlert("success", "Success", "Your account password has been updated");
                },
                (error) => {
                  this.updatingPassword = false;
                  this.closeLoadingAlert();
                  this.openCustomModal("editUserModal");
                  this.openResultAlert("error", "Error", error.error.message);
                }
              )
            },
            (error) => {
              this.updatingPassword = false;
              this.closeLoadingAlert();
              this.openCustomModal("editUserModal");
              this.openResultAlert("error", "Error", "An error occurred when changing password, please try again later!");
            }
          );
        }
      } catch (error) {
        this.updatingPassword = false;
        this.closeLoadingAlert();
        this.openCustomModal("editUserModal");
        this.openResultAlert("error", "Error", "Invalid login session, please log out and log in again, then try changing password again");
      }
    }
  }
}

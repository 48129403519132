import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { environment } from 'src/environments/environment';
import { CustomModalComponent } from '../common/custom-modal/custom-modal.component';
import { ResultAlertType, LoadingAlertType } from '../common/dropdown-menu/dropdown-menu.component';

type LoginRole = {
  roleId: string,
  roleName: string
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  content: any = {
    usernameLabel: 'Email address',
    passwordLabel: 'Password',
    newPasswordLabel: 'New Password',
    confirmNewPasswordLabel: 'Confirm New Password',
    signInBtn: 'Sign in'
  }
  form: any = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)])
  });
  updateForm:any = new UntypedFormGroup({
    newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)]),
    confirmNewPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)])
  });
  isLoggedIn: boolean = false;
  newPasswordSession: string = '';
  uiTabs: any = [];
  disabledBtnWhileCallingAPI: boolean = false;
  lastUrl: string = "";
  constructor(
    private router: Router,
    public formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    public utils: UTILS,
    private toast: CustomToastService,
    private tenantService: TenantService,
    private route: ActivatedRoute,
  ) { }

  resultElements:ResultAlertType = {
    type: 'success',
    title: '',
    desc: '',
    hideFooter: false
  }
  // Loading alert
  loadingElements:LoadingAlertType = {
    title: "",
    desc: undefined
  }

  // New custom modal
  @ViewChildren(CustomModalComponent) modalComponents!: QueryList<CustomModalComponent>;
  modalComponentList:CustomModalComponent[] = [];

  ngAfterViewInit(): void {
    this.modalComponentList = this.modalComponents.toArray();
  }

  openCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("open")
    } else {
      console.error("Invalid modal request!")
    }
  }
  closeCustomModal(modalComponent:string) {
    const getModal = this.modalComponentList.find(component => component.modalName === modalComponent);
    if(getModal) {
      getModal.toggleModal("close")
    } else {
      console.error("Invalid modal request!")
    }
  }
  openResultAlert(type:string, title:string, desc:string, hideFooter:boolean = false) {
    let tmpResultElements:ResultAlertType = {
      type: type,
      title: title,
      desc: desc,
      hideFooter: hideFooter
    }
    this.resultElements = tmpResultElements;
    this.openCustomModal('resultAlert');
  }
  closeResultAlert() {
    this.closeCustomModal('resultAlert');
  }
  openLoadingAlert(title:string, desc?:string) {
    this.loadingElements = {
      title: title,
      desc: desc
    }
    this.openCustomModal('loadingAlert');
  }
  closeLoadingAlert() {
    this.closeCustomModal('loadingAlert');
  }

  ngOnInit(): void {
    if (this.tokenStorageService.getToken()) {
      this.isLoggedIn = true;
      this.router.navigateByUrl("/dashboard");
    } else {
      this.route.queryParamMap.subscribe(params => {
        let routeLastUrl = "";
        let paramKeys = params.keys;
        const paramLastUrl = params.get('lastUrl');
        if(paramKeys.includes('lastUrl') && paramLastUrl) {
          routeLastUrl = paramLastUrl;
        } else {
          this.lastUrl = "";
        }
        if(!this.utils.isLocalEnvironment() && environment.hotel !== "admin") {
          let loginUrl = "";
          const adminEnv = "admin.nelson.management";
          const getLastUrl = encodeURIComponent(routeLastUrl);
          loginUrl = `https://${adminEnv}/#/login`;
          // if(routeLastUrl) {
          //   loginUrl = `https://${adminEnv}/#/login${!getLastUrl.includes("forceSignOut") ? "?lastUrl=" + getLastUrl : ""}`;
          // } else {
          //   loginUrl = `https://${adminEnv}/#/login`;
          // }
          window.open(loginUrl, '_self');
        } else {
          this.lastUrl = routeLastUrl;
        }
      })
    }
  }

  checkValidateForm(type:string): Boolean {
    let isErrorForm = false;
    // Check email 
    if (this.form.controls.email.status === "INVALID") {
      if (this.form.controls.email.errors.required) {
        this.toast.error("Email is required.", 'Error!');
        return isErrorForm;
      }
      if (this.form.controls.email.errors.email) {
        this.toast.error("Please, enter valid email address.", 'Error!');
        return isErrorForm;
      }
    }
    if (type === "updatePassword") {
      if (this.updateForm.controls.newPassword.status === "INVALID") {
        if (this.updateForm.controls.newPassword.errors.required) {
          this.toast.error("Please enter a new password.", 'Error!');
          return isErrorForm;
        }
        if (this.updateForm.controls.newPassword.errors) {
          this.toast.error("Please enter a valid new password.", 'Error!');
          return isErrorForm;
        }
      }
      if (this.updateForm.controls.newPassword.value != this.updateForm.controls.confirmNewPassword.value) {
        this.toast.error("New password and confirm password do not match.", 'Error!');
        return false;
      }
    }
    else {
      // Check password
      if (this.form.controls.password.status === "INVALID") {
        if (this.form.controls.password.errors.required) {
          this.toast.error("Password is required.", 'Error!');
          return isErrorForm;
        }
        if (this.form.controls.password.errors) {
          this.toast.error("Please enter a valid password.", 'Error!');
          return isErrorForm;
        }
      }
    }
    return !isErrorForm;
  }
  get watchSignInForm() {
    return this.form.controls;
  }
  get isDisabledSignInBtn() {
    return !!this.form.invalid;
  }
  onSubmit(): void {
    if (!this.checkValidateForm("signin")) {
      return;
    }
    this.disabledBtnWhileCallingAPI = true;
    this.openLoadingAlert("Signing in...");
    this.authService.login(this.form.value.email, this.form.value.password).subscribe(
      data => {
        this.closeLoadingAlert();
        if (data.challenge) {
          this.newPasswordSession = data.session;
          this.openCustomModal("updatePasswordModal");
        }
        else if (data.token) {
          this.toast.success("Log in successfully", 'Success!');
          this.tokenStorageService.saveRefreshTokenToken(data.refreshtoken);
          this.performLogin(data.token, this.form.value.email);
        }
        this.disabledBtnWhileCallingAPI = false;
      },
      err => {
        this.closeLoadingAlert();
        this.toast.error('Login failed. Please check credentials', 'Invalid credentials!');
        this.disabledBtnWhileCallingAPI = false;
        this.content.signInBtn = 'Sign in';
      }
    );
  }
  updatePassword() {
    if (!this.checkValidateForm("updatePassword")) {
      return;
    }
    this.closeCustomModal("updatePasswordModal");
    this.openLoadingAlert("Updating password...");
    this.authService.confirmUser(this.form.value.email, this.updateForm.controls.newPassword.value, this.newPasswordSession).subscribe(
      data => {
        if (data.token) {
          this.closeLoadingAlert();
          this.openResultAlert("success", "Success", "Account has been updated, signing in your account...", true);
          setTimeout(() => {
            this.performLogin(data.token, this.form.value.email);
          }, 3000);
        }
        this.disabledBtnWhileCallingAPI = false;
      },
      err => {
        console.log(err)
        this.openCustomModal("updatePasswordModal");
        this.closeLoadingAlert();
        this.openResultAlert("error", "Error", "An error occurred when updating your account password, please try again later")
        this.disabledBtnWhileCallingAPI = false;
      }
    );
  }
  performLogin(token: string, email: string) {
    this.tokenStorageService.saveToken(token);
    this.tokenStorageService.saveUser(this.tokenStorageService.decodeJwtToken(token).name);
    const roles = this.tokenStorageService.decodeJwtToken(token).roles;
    window.localStorage.removeItem("role");
    this.isLoggedIn = true;
    localStorage.setItem("isUserManagement", `${this.utils.isRoleUserManagement() || this.tokenStorageService.getEnvironmentIds().includes(',')}`);
    this.uiTabs = this.tokenStorageService.getPagesFromUserRoles(roles);
    if (this.tokenStorageService.getEnvironmentIds().includes(',') || this.utils.isRoleUserManagement()) {
      if (this.uiTabs && this.uiTabs.length > 0) {
        this.router.navigateByUrl("/" + this.uiTabs[0]);
      }
    } else {
      this.tenantService.loadTenants({ tenantids: this.tokenStorageService.getTenantIds(), environmentids: this.tokenStorageService.getEnvironmentIds() }).subscribe(
        (data: any) => {
          let tokenStorage = this.tokenStorageService.getToken();
          let refreshTokenStorage = this.tokenStorageService.getRefreshToken();
          if (!this.utils.isLocalEnvironment()) {
            this.isLoggedIn = false;
            window.localStorage.clear();
          }
          if(tokenStorage && refreshTokenStorage) {
            window.open(`${data.tenants[0].environments[0].managementsitehost}/#/${this.uiTabs[0]}?token=${tokenStorage}&refreshtoken=${refreshTokenStorage}`, '_self');
          }
        },
        (errResp: any) => {
          this.toast.error("Error loading environment.", 'Error!');
          this.isLoggedIn = false;
          this.tokenStorageService.logOut();
        }
      )
    }
    // if(this.lastUrl) {
    //   let refreshTokenStorage = this.tokenStorageService.getRefreshToken();
    //   const finalUrl = this.lastUrl.includes("?") ? `&token=${token}&refreshtoken=${refreshTokenStorage}` : `?token=${token}&refreshtoken=${refreshTokenStorage}`;
    //   window.open(`${this.lastUrl}${finalUrl}`, "_self")
    // } else {
    //   if (this.tokenStorageService.getEnvironmentIds().includes(',') || this.utils.isRoleUserManagement()) {
    //     if (this.uiTabs && this.uiTabs.length > 0) {
    //       this.router.navigateByUrl("/" + this.uiTabs[0]);
    //     }
    //   }
    //   else {
    //     this.tenantService.loadTenants({ tenantids: this.tokenStorageService.getTenantIds(), environmentids: this.tokenStorageService.getEnvironmentIds() }).subscribe(
    //       (data: any) => {
    //         let tokenStorage = this.tokenStorageService.getToken();
    //         let refreshTokenStorage = this.tokenStorageService.getRefreshToken();
    //         if (!this.utils.isLocalEnvironment()) {
    //           this.isLoggedIn = false;
    //           window.localStorage.clear();
    //         }
    //         window.open(`${data.tenants[0].environments[0].managementsitehost}/#/${this.uiTabs[0]}?token=${tokenStorage}&refreshtoken=${refreshTokenStorage}`, '_self');
    //       },
    //       (errResp: any) => {
    //         this.toast.error("Error loading environment.", 'Error!');
    //         this.isLoggedIn = false;
    //         this.tokenStorageService.logOut();
    //       }
    //     )
    //   }
    // }
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MaintenanceService } from 'src/app/services/maintenance.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import * as _ from 'lodash';
import { formatDate } from '@angular/common';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { CONSTANT } from 'src/app/helpers/constants';
import { environment } from 'src/environments/environment';
import { HotelWebConfig } from 'src/app/@types/app';
import { UTILS } from 'src/app/helpers/utils';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-maintenance-details',
  templateUrl: './maintenance-details.component.html',
  styleUrls: ['./maintenance-details.component.sass']
})
export class MaintenanceDetailsComponent implements OnInit {

  title:string = "Maintenance Reservations";

  hotels: any[] = [];
  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;

  floorList:MenuType[] = [];
  selectedFloor?:MenuType;

  categoryList:MenuType[] = [];
  selectedCategory?:MenuType;

  disableHotelMenu:boolean = false;

  rooms: any[] = [];
  filteredRooms: any[] = [];
  activeRoomLeft!: number;
  activeRoomLeftStart!: number;
  activeRoomLeftEnd!: number;
  activeRoomRight!: number;
  activeRoomRightStart!: number;
  activeRoomRightEnd!: number;
  maintenanceInfo: any;
  requestedID!:any;

  today:any = new Date(new Date().setHours(0,0,0,0));
  minStart:any = new Date(new Date().setHours(0,0,0,0));
  minEnd:any = new Date(new Date().setHours(0,0,0,0));
  startDate:any = new Date(new Date().setHours(16, 0, 0, 0));
  endDate:any = new Date(new Date(new Date().getTime() + 24*3600*1000).setHours(12, 0, 0, 0));
  prevStartDate:any = null;
  prevEndDate:any = null;
  isFormValid:boolean = false;
  notAllowedEditRoom:boolean = false;
  isShowHotel:boolean = false;
  isShowFloor:boolean = false;
  isShowCategory:boolean = false;
  disabledBtnWhileCallingAPI: boolean = false;
  mappingExistingDate:boolean = false;

  isCreate!:boolean;

  token:any;
  getTokenRoles:any;

  datepickerCustom: DatepickerDateCustomClasses[];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private maintenanceService: MaintenanceService,
    private toast: CustomToastService,
    private tokenStorageService: TokenStorageService,
    private storeService: StoreService,
    public utils: UTILS
  ) { 
    this.datepickerCustom = []
  }

  ngOnInit(): void {
    this.getData();
  }

  selectHotel(item:MenuType) {
    this.selectedHotel = item;
    this.getFloorList(Number(item.value))
  }

  selectFloor(item:MenuType) {
    this.selectedFloor = item;
  }
  selectAllFloor() {
    this.selectedFloor = this.floorList[0]
  }

  selectCategory(item:MenuType) {
    this.selectedCategory = item;
  }
  selectCategoryBasedOnValue(value:any) {
    const findCategory = this.categoryList.find(item => item.value === value);
    if(findCategory) {
      this.selectCategory(findCategory);
    }
  }

  updateDatepicker() {
    let custom = [
      {date: this.startDate, classes: ['selectedDate', 'start']},
    ]
    let tmpTime = this.startDate.getTime() + 24*3600*1000;
    while(tmpTime < this.endDate.getTime()) {
      custom.push({
        date: new Date(tmpTime), classes: ['dateRange']
      });
      tmpTime += 24*3600*1000;
    }
    custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
    this.datepickerCustom = custom
  }

  maintenanceDetailForm = new UntypedFormGroup({
    reason: new UntypedFormControl('', [Validators.required]),
  })

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  getData(): void {
    this.token = localStorage.getItem('jwt');
    this.getTokenRoles = this.tokenStorageService.decodeJwtToken(this.token).roles;
    let requestId:any;
    if(this.route.snapshot.paramMap.get('request') == 'create') {
      this.isCreate = true;
      requestId = false;
    } else {
      this.isCreate = false;
      this.requestedID = this.route.snapshot.paramMap.get('request');
      requestId = this.route.snapshot.paramMap.get('request');
      this.disableHotelMenu = true;
    }

    this.getMaintenanceId(requestId, () => {
      this.maintenanceService.getHotels().subscribe(data => {
        this.hotels = data;
        let tmpHotelList:MenuType[] = [];
        data.forEach((hotel:any) => {
          tmpHotelList.push({
            label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
            value: hotel.hotelId.toString(),
            disabled: false
          })
        })
        this.hotelList = tmpHotelList;
        let checkInHour = Number(data[0].defaultCheckInTime.split(":")[0]);
        let checkInMinute = Number(data[0].defaultCheckInTime.split(":")[1]);
        let checkOutHour = Number(data[0].defaultCheckOutTime.split(":")[0]);
        let checkOutMinute = Number(data[0].defaultCheckOutTime.split(":")[1]);
        if(this.isCreate) {
          this.route.queryParamMap.subscribe(params => {
            let paramKeys = params.keys;
            let checkBasicParams = ['hotelName', 'roomIndex'].every((key:any) => paramKeys.includes(key));
            if(checkBasicParams) {
              let queryHotel = this.hotels.find(hotel => hotel.hotelId === Number(params.get('hotelName')));
              if(queryHotel){
                const findHotelItem = this.hotelList.find(hotel => hotel.value === queryHotel.hotelId.toString());
                if(findHotelItem) {
                  this.selectHotel(findHotelItem)
                }
              }
            } else {
              //Create new maintenance 
              this.selectHotel(tmpHotelList[0])
            }
            let checkDateParams = ['start', 'end'].every((key:any) => paramKeys.includes(key));
            const getStart = params.get('start');
            const getEnd = params.get('end');
            if(checkDateParams && getStart && getEnd) {
              this.startDate = new Date(new Date(getStart).setHours(checkInHour, checkInMinute, 0, 0));
              this.endDate = new Date(new Date(new Date(getEnd).getTime()).setHours(checkOutHour, checkOutMinute, 0, 0));
            } else {
              let tmpStartDate = new Date(new Date().setHours(checkInHour, checkInMinute, 0, 0));
              let tmpEndDate = new Date(new Date().setHours(checkOutHour, checkOutMinute, 0, 0));
              if(tmpStartDate.getTime() < new Date().getTime()) {
                this.startDate = new Date();
              } else {
                this.startDate = tmpStartDate;
              }
              this.endDate = tmpEndDate
            }
          })
        } else {
          //Details of existing reservation
          this.mappingExistingDate = true;
          let infoHotelLabel = _.find(this.hotels, (o) => {return o.hotelId == this.maintenanceInfo.hotelId});
          if(infoHotelLabel) {
            this.startDate = new Date(this.maintenanceInfo.startDate + " " + this.maintenanceInfo.startTime);
            this.endDate = new Date(this.maintenanceInfo.endDate + " " + this.maintenanceInfo.endTime);
            this.prevStartDate = new Date(this.maintenanceInfo.startDate + " " + this.maintenanceInfo.startTime);
            this.prevEndDate = new Date(this.maintenanceInfo.endDate + " " + this.maintenanceInfo.endTime);
            
            const findHotelItem = this.hotelList.find(hotel => hotel.value === infoHotelLabel.hotelId.toString());
            if(findHotelItem) {
              this.selectHotel(findHotelItem)
            }
            this.maintenanceDetailForm.patchValue({
              reason: this.maintenanceInfo.reason,
            });
            if(new Date(this.startDate.getTime() - 24*3600*1000).setHours(0,0,0,0) <= new Date().getTime()) {
              this.notAllowedEditRoom = true;
            } else {
              this.notAllowedEditRoom = false;
            }
            setTimeout(() => {
              this.isFormValid = true;
            }, 300);
          } else {
            this.router.navigate(['/maintenance'])
          }
        }
        setTimeout(() => {
          this.mappingExistingDate = false
        }, 200);
      });
      this.maintenanceService.getCategories().subscribe(data => {
        let tmpCategory:MenuType[] = [];
        data.forEach((item:any) => {
          tmpCategory.push({
            label: item.label,
            value: item.name,
            disabled: false
          })
        })
        this.categoryList = tmpCategory;
        if(this.isCreate) {
          this.selectCategory(tmpCategory[0])
        } else {
          this.selectCategoryBasedOnValue(this.maintenanceInfo.category);
        }
      })
    })
  }

  getMaintenanceId(requestId:any, callback:any) {
    if(requestId) {
      this.maintenanceService.getMaintenanceInfo(requestId).subscribe(info => {
        this.maintenanceInfo = info;
        return callback();
      })
    } else {
      return callback();
    }
  }

  getFloorList(hotelId:number) {
    this.maintenanceService.getRooms({'hotelId': hotelId}).subscribe(data => {
      let availableRoom =  _.orderBy(_.filter(data, {available: true}), 'label', 'asc');
      availableRoom.sort((a,b) => Number(a.label) - Number(b.label))
      this.rooms = availableRoom.map(v => ({...v, selected: false, status: null}));
      if(!this.isCreate) {
        for (let i = 0; i < this.rooms.length; i++) {
          this.maintenanceInfo.rooms.forEach((item:any) => {
            if(this.rooms[i].label == item.label) {
              this.rooms[i].selected = true;
              this.rooms[i].status = item.status;
            }
          });
        }
      }
      this.route.queryParamMap.subscribe(params => {
        let paramKeys = params.keys;
        let checkBasicParams = ['hotelName', 'roomIndex'].every((key:any) => paramKeys.includes(key));
        if(checkBasicParams) {
          let getRoomId = Number(params.get('roomIndex'));
          const roomIndex = this.rooms.findIndex(room => room.roomId === getRoomId);
          this.rooms[roomIndex].selected = true;
        }
      })

      const floorGroup = _.keys(_.groupBy(availableRoom, this.getFloor));
      let tmpFloorList:MenuType[] = [{
        label: "All",
        value: "all",
        disabled: false
      }];
      floorGroup.forEach(floor => {
        tmpFloorList.push({
          label: floor,
          value: floor,
          disabled: false
        })
      })
      this.floorList = tmpFloorList;
      this.selectAllFloor();
    })
  }

  checkRoom(room:any, type:string) {
    switch(type) {
      case 'left':
        if(!room.selected && this.selectedFloor) {
          if(this.selectedFloor.value === "all") {
            return true;
          } else {
            return room.label[0] === this.selectedFloor.value;
          }
        } else {
          return false;
        }
      case 'right':
        return room.selected;
    }
  }

  checkDone(status:string) {
    if(status == 'DONE') {
      return true;
    } else {
      return false;
    }
  }

  getFloor(room:any) {
    return room.label[0];
  }

  selectRoom(type:string) {
    switch(type) {
      case 'add':
        let selectedItemsAdd = document.querySelectorAll("#leftRoom .room-item.active");
        let selectedLabelsAdd:any = [];
        selectedItemsAdd.forEach(item => {
          selectedLabelsAdd.push(item.getAttribute("data-label"))
        });
        for (let i = 0; i < this.rooms.length; i++) {
          if(selectedLabelsAdd.includes(this.rooms[i].label)) {
            this.rooms[i].selected = true;
          }
        }
        break;
      case 'remove':
        let selectedItemsRemove = document.querySelectorAll("#rightRoom .room-item.active");
        let selectedLabelsRemove:any = [];
        selectedItemsRemove.forEach(item => {
          selectedLabelsRemove.push(item.getAttribute("data-label"))
        });
        for (let i = 0; i < this.rooms.length; i++) {
          if(selectedLabelsRemove.includes(this.rooms[i].label)) {
            this.rooms[i].selected = false;
          }
        }
        break;
    }
    setTimeout(() => {
      this.validateForm();
    }, 200);
  }

  selectListRoom(e:any,type:string) {
    if(e.shiftKey) {
      if(this.activeRoomLeft >= 0) {
        if(this.activeRoomLeftStart == this.activeRoomLeft) {
          if(this.activeRoomLeftEnd > this.activeRoomLeftStart) {
            for (let i = this.activeRoomLeftStart; i <= this.activeRoomLeftEnd; i++) {
              document.querySelector(`#${type} .room-item[data-index="${i}"]`)?.classList.remove("active");
            }
          } else {
            for (let i = this.activeRoomLeftStart; i >= this.activeRoomLeftEnd; i--) {
              document.querySelector(`#${type} .room-item[data-index="${i}"]`)?.classList.remove("active");
            }
          }
          this.activeRoomLeftEnd = parseInt(e.currentTarget.dataset.index);
        } else {
          this.activeRoomLeftEnd = parseInt(e.currentTarget.dataset.index);
          this.activeRoomLeftStart = this.activeRoomLeft;
        }

        if(this.activeRoomLeftEnd > this.activeRoomLeftStart) {
          for (let i = this.activeRoomLeftStart; i <= this.activeRoomLeftEnd; i++) {
            document.querySelector(`#${type} .room-item[data-index="${i}"]`)?.classList.add("active");
          }
        } else {
          for (let i = this.activeRoomLeftStart; i >= this.activeRoomLeftEnd; i--) {
            document.querySelector(`#${type} .room-item[data-index="${i}"]`)?.classList.add("active");
          }
        }
      }
    } else if (e.ctrlKey) {
      e.currentTarget.classList.toggle("active");
      if(e.currentTarget.classList.contains("active")){
        this.activeRoomLeft = parseInt(e.currentTarget.dataset.index);
      } else {
        this.activeRoomLeft = -1;
      }
    } else {
      this.activeRoomLeft = parseInt(e.currentTarget.dataset.index);
      let tmpAllItem = document.querySelectorAll(`#${type} .room-item.active`);

      if(e.currentTarget.classList.contains("active")) {
        e.currentTarget.classList.remove("active");
      } else {
        for (let i = 0; i < tmpAllItem.length; i++) {
          tmpAllItem[i].classList.remove("active");
        }
        e.currentTarget.classList.add("active");
      }
    }
  }

  //Validate form
  validateForm():void {
    let validReason = this.maintenanceDetailForm.controls.reason.valid;
    let selectedRoom = document.querySelectorAll('#rightRoom .room-item');
    if(validReason && selectedRoom.length > 0 && this.startDate != "Invalid Date" && this.endDate != "Invalid Date") {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }
  disableUpdate() {
    this.isFormValid = false;
  }
  setDateFromDropdown(value:Date, type:string) {
    switch (type) {
      case "startDate":
        this.startDate = value;
        break;
      case "endDate":
        this.endDate = value;
        break;
      default:
        break;
    }
  }
  dateChange(type:string) {
    if(!this.mappingExistingDate) {
      switch(type) {
        case 'startDate':
          if(!this.prevStartDate) {
            this.prevStartDate = this.startDate;
          } else {
            const prevStartMonth = this.prevStartDate.getMonth();
            const newStartMonth = this.startDate.getMonth();
            const prevStartDay = this.prevStartDate.getDate();
            const newStartDay = this.startDate.getDate();
            if(prevStartMonth !== newStartMonth || prevStartDay !== newStartDay) {
              this.startDate = new Date(this.startDate.setHours(this.prevStartDate.getHours(), this.prevStartDate.getMinutes(), 0, 0));
              this.prevStartDate = this.startDate;
            } else {
              this.prevStartDate = this.startDate;
            }
          }
          this.minEnd = this.startDate;
          if(this.startDate.getTime() > this.endDate.getTime()) {
            let tmpStartDate = new Date(this.startDate.getTime())
            let endDateHour = this.endDate.getHours();
            let endDateMin = this.endDate.getMinutes();
            let newEndDate = new Date(new Date(tmpStartDate.setHours(this.prevEndDate.getHours(), this.prevEndDate.getMinutes(), 0, 0) + 24*3600*1000).setHours(endDateHour, endDateMin, 0, 0));
            this.endDate = newEndDate;
          }
          break;
        case 'endDate':
          if(!this.prevEndDate) {
            this.prevEndDate = this.endDate;
          } else {
            const prevEndMonth = this.prevEndDate.getMonth();
            const newEndMonth = this.endDate.getMonth();
            const prevEndDay = this.prevEndDate.getDate();
            const newEndDay = this.endDate.getDate();
            if(prevEndMonth !== newEndMonth || prevEndDay !== newEndDay) {
              this.endDate = new Date(this.endDate.setHours(this.prevEndDate.getHours(), this.prevEndDate.getMinutes(), 0, 0));
              this.prevEndDate = this.endDate;
            } else {
              this.prevEndDate = this.endDate;
            }
          }
          break;
      }
    }
    this.updateDatepicker();
    this.validateForm();
  }

  //Convert date
  convertDate(date:any, type:string) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth()+1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    switch (type) {
      case 'YYYY-MM-dd':
        return `${year}-${month}-${day}`;
      case 'dd.MM.YYYY':
        return `${day}.${month}.${year}`;
      case 'MM.YYYY':
        return `${month}.${year}`;
    }
    return;
  }

  createDate(stringDate:any) {
    let extract = stringDate.split('-');
    return new Date(extract[0], extract[1]-1, extract[2]);
  }

  //Cancel maintenance
  cancelMaintenance():void {
    this.maintenanceService.cancelMaintenance(this.requestedID).subscribe(
      data => {
        this.toast.success("Maintenance reservations cancelled!", 'Success!');
        this.router.navigate(['/maintenance']);
      },
      err => {
        this.toast.error(err.error.message, "Error!");
      }
    )
  }

  //Create maintenance
  onUpdateMaintenance():void{
    let categoryName = this.selectedCategory ? this.selectedCategory.value : "";
    let hotelId = this.selectedHotel ? Number(this.selectedHotel.value) : "";
    
    let selectedRoom = _.filter(this.rooms, {selected: true});
    let roomRequest:any[] = [];
    this.disabledBtnWhileCallingAPI = true;
    if(this.startDate.getTime() > this.endDate.getTime()) {
      this.toast.error('Start date must be before end date!', 'Error!');
      this.disabledBtnWhileCallingAPI = false;
    } else {
      if(this.isCreate) {
        for (let i = 0; i < selectedRoom.length; i++) {
          roomRequest.push({
            roomId: selectedRoom[i].roomId,
            label: selectedRoom[i].label,
            status: "WAITING"
          })
        }
        
        let createRequest = {
          'category': categoryName,
          'hotelId': hotelId,
          'startDate': formatDate(this.startDate, "yyyy-MM-dd", "en-US"),
          'startTime': formatDate(this.startDate, "HH:mm", "en-US"),
          'endDate': formatDate(this.endDate, "yyyy-MM-dd", "en-US"),
          'endTime': formatDate(this.endDate, "HH:mm", "en-US"),
          'reason': this.maintenanceDetailForm.controls.reason.value,
          'rooms': roomRequest
        };
        this.maintenanceService.createMaintenance(createRequest).subscribe(
          data => {
            this.toast.success("Maintenance reservations created!", 'Success!');
            let usageParams:any = {
              hotelId: hotelId,
              startDate: data.startDate,
              endDate: data.endDate,
              maintenanceReservationId: data.id
            };
            this.disabledBtnWhileCallingAPI = false;
            this.router.navigate(['/usage'], {queryParams: usageParams})
          },
          err => {
            this.showErrorMessage(err);
            this.disabledBtnWhileCallingAPI = false;
        });
      } else {
        selectedRoom.forEach((item:any) => {
          let status;
          let getCheckbox = document.querySelector(`#rightRoom .room-item[data-label="${item.label}"] input:checked`);
          if(getCheckbox) {
            status = "DONE";
          } else {
            status = "WAITING";
          }
          roomRequest.push({
            roomId: item.roomId,
            label: item.label,
            status: status,
          })
        })
        
        let createRequest = {
          'cancelled': null,
          'category': categoryName,
          'hotelId': hotelId,
          'id': this.requestedID,
          'startDate': formatDate(this.startDate, "yyyy-MM-dd", "en-US"),
          'startTime': formatDate(this.startDate, "HH:mm", "en-US"),
          'endDate': formatDate(this.endDate, "yyyy-MM-dd", "en-US"),
          'endTime': formatDate(this.endDate, "HH:mm", "en-US"),
          'reason': this.maintenanceDetailForm.controls.reason.value,
          'rooms': roomRequest
        };
        this.maintenanceService.createMaintenance(createRequest).subscribe(
          data => {
            this.toast.success("Maintenance reservations updated!", 'Success!');
            this.getData();
            this.disabledBtnWhileCallingAPI = false;
          },
          err => {
            this.showErrorMessage(err);
            this.disabledBtnWhileCallingAPI = false;
          });
      }
    }
  }

  showErrorMessage(err: any) {
    this.toast.error(err.error.message, "Error!");
  }

  openUsage() {
    let usageParams:any = {
      hotelId: this.maintenanceInfo.hotelId,
      startDate: this.maintenanceInfo.startDate,
      endDate: this.maintenanceInfo.endDate,
      maintenanceReservationId: this.maintenanceInfo.id
    };
    this.router.navigate(['/usage'], {queryParams: usageParams})
  }

}

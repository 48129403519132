<header class="header">
  <div class="header-container">
    <div class="mui-header-container">
      <div class="mui-header-brand">
        <div>
          <img class="brand-logo" src="assets/images/nelson-logo.png">
        </div>
      </div>
      <div class="mui-header-tools">
        <div class="header-item">
          <div class="email-account" (click)="openEditCurrentUser()">
            <span class="avatar">{{renderAvatar()}}</span>
          </div>
        </div>
        <div class="header-item">
          <button class="btn btn-yellow small-text" (click)="logout()">
            <fa-icon type="solid" icon="arrow-right-from-bracket"></fa-icon>Sign out
          </button>
        </div>
        <div class="header-item tablet-hide">
          <div class="btn-group page-menu-btn" dropdown>
            <button type="button" dropdownToggle>
              <fa-icon type="solid" icon="bars"></fa-icon>
            </button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              @for (item of menuItems; track item) {
                <div class="dropdown-item" [ngClass]="{'selected': item.pageName === activePage}" routerLink="/{{ item.pageName }}">
                  {{ item.friendlyName }}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    @if (menuItems.length > 0) {
      <div class="bottom-header mobile-hide">
        <div class="nav-container">
          @for (item of menuItems; track item) {
            <div class="nav-item" [ngClass]="{'item-active': item.pageName === activePage}">
              <a routerLink="/{{ item.pageName }}">{{ item.friendlyName }}</a>
              <div class="nav-selected">
                <div class="selected-arrow"></div>
              </div>
            </div>
          }
        </div>
      </div>
    }
  </div>
</header>
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { TokenStorageService } from './token-storage.service';
import { isBuffer } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router,
    private toast: CustomToastService,
    private tokenStorageService: TokenStorageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.error instanceof ErrorEvent
        ) {
          console.error('A client-side or network error occurred', error.error);

          // else {
          //   console.log(`Backend returned code: ${error.status} ${error.statusText}`);
          //   switch(error.status){
          //     case 401:
          //       if (error.error.error === "Unauthorized") {
          //         this.tokenStorageService.logOut();
          //         console.error(error.error.message, 'Error!');
          //       } else if(error.error.error === "AUTHENTICATION_FAILED"){
          //         break;
          //       } else  {
          //         this.tokenStorageService.logOut();
          //         console.error("Unauthorized", 'Error!');
          //       }
          //       break;
          //   }
          // }
        }
        let token = this.tokenStorageService.getToken();
        let refreshToken = this.tokenStorageService.getRefreshToken();

        if (error.status === 401 && (!token || !refreshToken)) {
          this.tokenStorageService.logOutBackToAdmin();
          console.error('Unauthorized', 'Error!');
        }
        if (error.status === 401 && token && refreshToken) {
          if(req.url === "/api/user/login") {
            this.tokenStorageService.logOutBackToAdmin();
          } else {
            return this.tokenStorageService.reAuthenticate(
              this.tokenStorageService.decodeJwtToken(token)[
                'cognito:username'
              ] as string,
              refreshToken
            )
            .pipe(
              switchMap((data) => {
                let authReq = req;
                if (data.token) {
                  this.tokenStorageService.saveToken(data.token);
                  authReq = req.clone({
                    headers: req.headers.set(
                      'Authorization',
                      'Bearer ' + data.token
                    ),
                  });
                }
                return next.handle(authReq).pipe(
                  catchError((errorRetry) => {
                    if (errorRetry.status !== 200) {
                      this.tokenStorageService.logOutBackToAdmin();
                    }
                    return throwError(error);
                  })
                );
              }),
              catchError((errorRefreshTokenReq) => {
                if (errorRefreshTokenReq.status !== 200) {
                  this.tokenStorageService.logOutBackToAdmin();
                }
                return throwError(error);
              })
            );
          }
        }
        return throwError(error);
      })
    );
  }
}

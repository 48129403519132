import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.sass'
})
export class PageNotFoundComponent {
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  constructor(
    private router: Router,
    private storeService: StoreService,
    private tokenStorageService: TokenStorageService,
  ) {}

  goBackToPage() {
    if(this.hotelConfig.hotel.hotelChainName === "adminPortal") {
      this.router.navigateByUrl('/dashboard');
    } else {
      const token = localStorage.getItem('jwt');
      if(token) {
        const getTokenRoles = this.tokenStorageService.decodeJwtToken(token).roles;
        let menuItems = this.tokenStorageService.getPageNamesFromUserRoles(getTokenRoles);
        if(menuItems.length) {
          this.router.navigateByUrl(menuItems[0].pageName);
        } else {
          this.router.navigateByUrl('/login');
        }
      } else {
        this.router.navigateByUrl('/login');
      }
    }
  }
}

import { Component, OnInit, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import {UTILS} from 'src/app/helpers/utils';
import { ReservationsService } from 'src/app/services/reservations.service';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { CONSTANT } from 'src/app/helpers/constants';
import { environment } from 'src/environments/environment';
import { AnimationOptions } from 'ngx-lottie';
import { StoreService } from 'src/app/services/store.service';
import { HotelWebConfig } from 'src/app/@types/app';
import { MenuType, ActionMenuType } from '../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.sass','../../../styles.sass']
})
export class ReservationsComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }

  datepickerCustom: DatepickerDateCustomClasses[];
  title = "Reservations";
  
  hotels: any[] = [];
  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;

  startDate:any;
  endDate:any;
  searchResult:any;
  totalCount: number = 0;
  limitSearchReservations: number = CONSTANT.LIMIT_SEARCH_RESERVATION;
  isLoading:boolean = false;
  isLoadingCountSearch:boolean = false;
  isMoreLoading:boolean = false;
  orderBy:string = "CHECK_IN";
  orderDir:string = "ASC";

  currentCount:number = 0;
  remainingCount:number = 0;
  showStatusFilter:boolean = false;

  modalRef!: BsModalRef;
  @ViewChild('createReservationModal') resendModalTemplate!:TemplateRef<any>;

  editAllowed:boolean = false;

  channelList:MenuType[] = [];
  selectedChannel?:MenuType;

  selectedDateMode:any;
  dateMode:any = [
    {'value': 'OVERLAP', 'label': 'Overlap'},
    {'value': 'EXACT', 'label': 'Exact'},
    {'value': 'ARRIVAL', 'label': 'Arrival'},
    {'value': 'DEPARTURE', 'label': 'Departure'},
  ]

  reservationStatus:any = [
    {'value': 'CONFIRMED', 'label': 'Confirmed', 'checked': false},
    {'value': 'PENDING_CONFIRMATION', 'label': 'Unconfirmed', 'checked': false},
    {'value': 'CANCELLED', 'label': 'Cancelled', 'checked': false},
    {'value': 'BLOCKED', 'label': 'Blocked', 'checked': false},
    {'value': 'CHECK_IN_INCOMPLETE', 'label': 'Check in incomplete', 'checked': false},
  ]

  selectedFilterLabel:string = "[Default]";

  newReservationActionMenu:ActionMenuType[] = [];

  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  filters:any = CONSTANT.FILTER_RESERVATIONS.filter(option => (option.value === "MONETARY_VOUCHER" && this.hotelConfig.feature.giftCard) || (option.value !== "MONETARY_VOUCHER"));
  prevQuery:any;
  constructor(
    private router: Router,
    public utils: UTILS,
    private reservationsService: ReservationsService,
    private modalService: BsModalService,
    private tokenStorageService: TokenStorageService,
    private storeService: StoreService
  ) { 
    this.datepickerCustom = []
  }

  ngOnInit(): void {
    let token = window.localStorage.getItem('jwt');
    if (token) {
      let roles = this.tokenStorageService.getRole();
      if(roles && ["NELSON_MANAGER", "CLIENT_ADMIN", "CUSTOMER_SERVICE"].includes(roles)) {
        this.editAllowed = true;
      } else {
        this.editAllowed = false;
      }
    }
    this.selectedDateMode = this.dateMode[0];
    let tmpChannels:MenuType[] = [{
      label: "All",
      value: "all",
      disabled: false
    }];
    CONSTANT.CHANNEL_BOOKING.forEach(channel => {
      if((CONSTANT.OTA_CHANNEL.includes(channel.value) && this.hotelConfig.MUIfeature.OTAServices.includes(channel.value)) || !CONSTANT.OTA_CHANNEL.includes(channel.value)) {
        tmpChannels.push({
          label: channel.label,
          value: channel.value,
          disabled: false
        });
      }
    })
    this.channelList = tmpChannels;
    this.newReservationActionMenu = [
      {
        title: "Normal",
        desc: "Create a reservation as an individual with up to 7 rooms",
        iconType: "solid",
        icon: "user",
        show: true,
        value: "NORMAL"
      },
      {
        title: "Group",
        desc: "Create a reservation for a tour, organization with no room limit and bulk editing features",
        iconType: "solid",
        icon: "users",
        show: this.hotelConfig.feature.groupBooking,
        value: "GROUP"
      }
    ]
    this.getData();
    this.getDomainSite();
  }

  showHotelBaseOnConfig(hotelLabel:string) {
    if(!this.hotelConfig.MUIfeature.useHotelCode) {
      const findHotel = this.hotels.find((hotel:any) => hotel.label === hotelLabel);
      if(findHotel) {
        return findHotel.name;
      } else {
        return hotelLabel
      }
    } else {
      return hotelLabel
    }
  }

  getDomainSite(){
      this.reservationsService.getDomainSiteHost().subscribe(data=>{
        CONSTANT.SITEHOST = "http://" + data;
      }
    );
  }

  updateDatepicker() {
    if(this.startDate && this.endDate) {
      let custom = [
        {date: this.startDate, classes: ['selectedDate', 'start']},
      ]
      let tmpTime = this.startDate.getTime() + 24*3600*1000;
      while(tmpTime < this.endDate.getTime()) {
        custom.push({
          date: new Date(tmpTime), classes: ['dateRange']
        });
        tmpTime += 24*3600*1000;
      }
      custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
      this.datepickerCustom = custom
    }
  }

  toggleStatusFilter() {
    this.showStatusFilter = !this.showStatusFilter;
    this.reservationStatus.forEach((status:any) => {
      status.checked = false;
    });
  }

  reservationSearch = new UntypedFormGroup({
    hotelLabel: new UntypedFormControl('', Validators.required),
    searchQuery: new UntypedFormControl('', Validators.required),
    channelLabel: new UntypedFormControl('', Validators.required),
  });

  getData(): void {
    this.reservationsService.getHotels().subscribe(data => {
      this.hotels = data;

      let tmpHotelList:MenuType[] = [{
        label: "All",
        value: "all",
        disabled: false
      }];
      data.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.hotelId.toString(),
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      setTimeout(() => {
        this.retrieveSearchFields();
      }, 100);
    });
  }
  selectHotel(item:MenuType) {
    this.selectedHotel = item;
  }
  selectAllHotel() {
    this.selectedHotel = this.hotelList[0]
  }

  selectChannel(item:MenuType) {
    this.selectedChannel = item;
  }
  selectAllChannel() {
    this.selectedChannel = this.channelList[0]
  }

  storeSearchFields() {
    localStorage.setItem('reservationFields', JSON.stringify({
      searchQuery: this.reservationSearch.controls.searchQuery.value,
      hotel: this.selectedHotel ? this.selectedHotel.value : this.hotelList[0].value,
      startDate: this.startDate,
      endDate: this.endDate,
      filters: this.filters,
      channel: this.selectedChannel ? this.selectedChannel.value : this.channelList[0].value,
      dateMode: this.selectedDateMode,
      reservationStatus: this.reservationStatus,
    }))
  }
  retrieveSearchFields() {
    let getStoredField = localStorage.getItem('reservationFields');
    if(getStoredField) {
      let getFields = JSON.parse(getStoredField);
      this.startDate = getFields.startDate ? new Date(getFields.startDate) : new Date(new Date().setHours(0,0,0,0));
      if(getFields.endDate) {
        this.endDate = new Date(getFields.endDate)
      } else {
        this.endDate = new Date(this.startDate.getTime() + 30*24*60*60*1000);
      }
      this.filters = getFields.filters;
      this.getCheckedFilterLabel();
      this.selectedDateMode = getFields.dateMode;
      this.reservationStatus = getFields.reservationStatus;
      this.reservationSearch.patchValue({
        searchQuery: getFields.searchQuery,
        hotelLabel: getFields.hotel,
        channelLabel: getFields.channel,
      })

      const getHotelField = getFields.hotel;
      if(getHotelField) {
        const findHotel = this.hotelList.find(hotel => hotel.value === getHotelField);
        if(findHotel) {
          this.selectHotel(findHotel);
        } else {
          this.selectAllHotel();
        }
      } else {
        this.selectAllHotel();
      }

      const getChannelField = getFields.channel;
      if(getChannelField) {
        const findChannel = this.channelList.find(channel => channel.value === getChannelField);
        if(findChannel) {
          this.selectChannel(findChannel);
        } else {
          this.selectAllChannel();
        }
      } else {
        this.selectAllChannel();
      }
      
    } else {
      this.resetSearchFields();
    }
  }
  resetSearchFields() {
    this.startDate = new Date(new Date().setHours(0,0,0,0));
    this.endDate = new Date(this.startDate.getTime() + 30*24*60*60*1000);
    this.selectAllHotel();
    this.selectAllChannel();
    this.reservationSearch.patchValue({
      searchQuery: "",
      hotelLabel: "All",
      channelLabel: "All",
    })
    this.reservationStatus.forEach((item:any) => {
      item.checked = false;
    });
    this.filters.forEach((item:any) => {
      if(item.value == "DISCOUNT_VOUCHER" || item.value == "MONETARY_VOUCHER" || item.value == "ROOM_NUMBER" || item.value == "SSN") {
        item.checked = false;
      } else {
        item.checked = true;
      }
    });
    this.getCheckedFilterLabel();
    this.searchResult = null;
    this.totalCount = 0;
    this.prevQuery = null;
  }

  openCreateReservation(type:string) {
    let isNormalType = type === "NORMAL";
    const getRefreshToken = this.tokenStorageService.getRefreshToken();
    if (this.utils.isLocalEnvironment()) {
      if (isNormalType) {
        this.tokenStorageService.redirectWithToken((token: any) => {
          window.open(CONSTANT.LOCALHOST_URL + `/?token=${token}&refreshtoken=${getRefreshToken}`);
        })
        return;
      }
      this.tokenStorageService.redirectWithToken((token: any) => {
        window.open(CONSTANT.GROUP_BOOKING_LOCALHOST + `?token=${token}&refreshtoken=${getRefreshToken}`);
      })
      return;
    }
    if (isNormalType) {
      this.tokenStorageService.redirectWithToken((token: any) => {
        window.open(CONSTANT.SITEHOST + CONSTANT.PATH_NAME_BOOKING + `/?token=${token}&refreshtoken=${getRefreshToken}`);
      })
      return;
    }
    this.tokenStorageService.redirectWithToken((token: any) => {
      window.open(CONSTANT.SITEHOST + CONSTANT.GROUP_BOOKING_URL  + `?token=${token}&refreshtoken=${getRefreshToken}`);
    })
  }
  openGuestInfo(uuid:string, event:any) {
    event.stopPropagation();
    if (this.utils.isLocalEnvironment()) {
      window.open(CONSTANT.CONFIRMATION_LOCALHOST + `/${uuid}`);
      return;
    }
    window.open(CONSTANT.SITEHOST + CONSTANT.CONFIRMATION_URL + `/${uuid}`);
  }

  changeDateMode(event:any) {
    this.selectedDateMode = event;
  }

  changeStatus(index:number, event:any) {
    this.reservationStatus[index].checked = event.target.checked;
  }

  getChannelLabel(id:string) {
    let findChannel = this.channelList.find((item:any) => {
      return item.value === id;
    });
    if(findChannel) {
      return findChannel.label;
    } else {
      return id
    }
  }

  changeFilter(index:number, event:any) {
    this.filters[index].checked = event.target.checked;
    if (this.filters[index].value == "SSN") {
      this.filters.forEach((item:any) => {
        if(item.value != "SSN") {
          item.checked = false;
        }
      });    
    } else {
      this.filters.forEach((item:any) => {
        if(item.value == "SSN") {
          item.checked = false;
        }
      });   
    }
    this.getCheckedFilterLabel();
  }
  getCheckedFilterLabel() {
    let defaultFilters:any = [];
    for (let i = 0; i < 6; i++) {
      defaultFilters.push(this.filters[i]);
    }
    let selectedFilters:any = [];
    this.filters.forEach((item:any) => {
      if(item.checked) {
        selectedFilters.push(item);
      }
    });
    if(selectedFilters.length == 0 || _.isEqual(defaultFilters, selectedFilters)) {
      this.selectedFilterLabel = "[Default]";
    } else {
      this.selectedFilterLabel = _.map(selectedFilters, 'label').join(", ");
    }
  }
  clearAllFilter() {
    this.filters.forEach((item:any) => {
      item.checked = false;
    });
    this.selectedFilterLabel = "[Default]";
  }

  toggleSorting(columnName:string) {
    this.isLoading = true;
    this.isLoadingCountSearch = true;
    if(this.orderBy == columnName) {
      if(this.orderDir == 'ASC') {
        this.orderDir = 'DESC';
      } else {
        this.orderDir = 'ASC';
      }
    } else {
      this.orderBy = columnName;
      this.orderDir = 'ASC';
    }
    this.search('sorting');
  }

  updateShowMoreCount() {
    this.currentCount = this.searchResult.reservations.length;
    let remaining = this.totalCount - this.currentCount;
    if(remaining <= this.limitSearchReservations) {
      this.remainingCount = remaining;
    } else {
      this.remainingCount = this.limitSearchReservations;
    }
  }
  search(type:string) {
    setTimeout(() => {
      switch(type) {
        case 'sorting':
          this.prevQuery.offset = 0;
          this.prevQuery.orderBy = this.orderBy;
          this.prevQuery.orderDir = this.orderDir;
          this.reservationsService.searchReservations(this.prevQuery).subscribe(data => {
            this.searchResult = data;
            this.updateShowMoreCount();
            this.isLoading = false;
            this.isLoadingCountSearch = false;
          })
          break;
        case 'showMore':
          this.isMoreLoading = true;
          this.prevQuery.offset += this.limitSearchReservations;
          this.reservationsService.searchReservations(this.prevQuery).subscribe(data => {
            data.reservations.forEach((item:any) => {
              this.searchResult['reservations'].push(item);
            });
            this.updateShowMoreCount();
            this.isMoreLoading = false;
          })
          break;
        default:
          this.isLoading = true;
          this.isLoadingCountSearch = true;
          this.storeSearchFields();
          let request:any = {
            query: this.reservationSearch.controls.searchQuery.value,
            orderBy: this.orderBy,
            orderDir: this.orderDir,
            offset: 0,
            limit: this.limitSearchReservations,
            dateMode: this.selectedDateMode.value,
            reservationStates: _.map(this.reservationStatus,(item:any) => {if(item.checked) return item.value; else return 0;}).filter((item:any) => {return item != 0;}),
            optionalFilters: _.map(this.filters,(item:any) => {if(item.checked) return item.value; else return 0;}).filter((item:any) => {return item != 0;}),
          }
          if(this.selectedHotel && this.selectedHotel.value != "all") {
            request.hotelId = this.selectedHotel.value;
          }
          if(this.selectedChannel && this.selectedChannel.value != "all") {
            request.channel = this.selectedChannel.value;
          }
          switch(type) {
            case 'search':
              if(this.startDate) {
                request.startDate = this.utils.convertDate(this.startDate,'YYYY-MM-dd');
              }
              if(this.endDate) {
                request.endDate = this.utils.convertDate(this.endDate,'YYYY-MM-dd');
              }
              break;
            case 'departingToday':
              request.endDate = this.utils.convertDate(new Date(),'YYYY-MM-dd');
              request.dateMode = "EXACT";
              break;
            case 'arrivingToday':
              request.startDate = this.utils.convertDate(new Date(),'YYYY-MM-dd');
              request.dateMode = "EXACT";
              break;
            case 'stayingTonight':
              request.startDate = this.utils.convertDate(new Date(),'YYYY-MM-dd');
              request.endDate = this.utils.convertDate(new Date(new Date().getTime() + 24*60*60*1000),'YYYY-MM-dd');
              request.dateMode = "OVERLAP";
              request.stayingTonight = true;
              break;
          }
          this.reservationsService.searchReservations(request).subscribe(data => {
            this.searchResult = data;
            this.prevQuery = request;
            if (data) {
              this.reservationsService.countReservations(request).subscribe(countNumber => {
                this.totalCount = countNumber;
                this.updateShowMoreCount();
                this.isLoadingCountSearch = false;
              });
            }
            this.isLoading = false;
          },err=>{
            this.isLoading = false;
          });
          
          break;
      }
    }, 100);
  }

  convertToArray(event:any) {
    let tmpArray:any[] = [];
    event.forEach((item:any) => {
      tmpArray.push(item);
    });
    return tmpArray
  }

  validateDate() {
    if(this.startDate == "Invalid Date") {
      this.startDate = null;
    }
    if(this.endDate == "Invalid Date") {
      this.endDate = null;
    }
  }

  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(this.startDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.endDate = new Date(this.startDate.getTime() + 24*3600*1000);
        }
        break;
      case 'endDate':
        if(this.endDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.startDate = new Date(this.endDate.getTime() - 24*3600*1000);
        }
        break;
    }
    this.updateDatepicker();
  }

  updateStartDate(event:any) {
    if(event) {
      this.reservationSearch.patchValue({
        startDate: this.utils.convertDate(event,'dd.MM.YYYY'),
      })
    }
  }
  updateEndDate(event:any) {
    if(event) {
      this.reservationSearch.patchValue({
        endDate: this.utils.convertDate(event,'dd.MM.YYYY'),
      })
    }
  }

  dateOffset() {
    switch(this.selectedDateMode.value) {
      case 'OVERLAP':
      case 'EXACT':
        return 1;
      default:
        return 0;
    }
  }
  currentActionTimeout:any = null;
  cancelActionTimeout(): void {
    clearTimeout(this.currentActionTimeout);
    this.currentActionTimeout = undefined;
  }
  actionDateInputChange(event:any,type:string) {
    this.cancelActionTimeout();
    this.currentActionTimeout = setTimeout(() => {
      if(this.reservationSearch.controls.startDate.valid || this.reservationSearch.controls.endDate.valid) {
        let inputDate = event.split('.');
        let newDay = parseInt(inputDate[0]);
        let newMonth = parseInt(inputDate[1]);
        let newYear = parseInt(inputDate[2]);
        switch(type) {
          case 'startDate':
            if(this.reservationSearch.controls.startDate.valid) {
              let newStartDate = new Date(newYear, newMonth - 1, newDay);
              if(this.endDate < newStartDate && this.endDate) {
                let newEnd = new Date(newYear, newMonth - 1, newDay + this.dateOffset());
                this.reservationSearch.patchValue({
                  endDate: this.utils.convertDate(newStartDate, 'dd.MM.YYYY')
                })
                this.endDate = newEnd;
              }
              this.startDate = newStartDate;
            }
            break;
          case 'endDate':
            if(this.reservationSearch.controls.endDate.valid) {
              let newEndDate = new Date(newYear, newMonth - 1, newDay);
              if(this.startDate > newEndDate && this.startDate) {
                let newStart = new Date(newYear, newMonth - 1, newDay - this.dateOffset());
                this.reservationSearch.patchValue({
                  startDate: this.utils.convertDate(newStart, 'dd.MM.YYYY')
                })
                this.startDate = newStart;
              }
              this.endDate = newEndDate;
            }
            break;
        }
      }
    }, 500);
  }
  getPartialFilter(){
    if(window.innerWidth < 850) {
      return this.getAllFilter().slice(0, 2);
    } else {
      return this.getAllFilter().slice(0, 3);
    }
  }
  getAllFilter(){
    return this.filters.filter((f: any) => f.checked);
  }
  removeFilter(filter: any, e: any) {
    e.stopPropagation();
    this.filters.filter((f: any) => f.value == filter.value)[0].checked = false;
  }
  openReservation(uuid:string) {
    window.open(`${window.location.href}/${uuid}`, "_blank")
  }
  checkEmptyGuests(guestByRoom:any) {
    let hasGuest = false;
    const guestList = Object.values(guestByRoom);
    guestList.forEach((guest:any) => {
      if(guest[0].firstName || guest[0].lastName) {
        hasGuest = true;
      }
    })
    return !hasGuest;
  }
}

<div class="login-page">
  <div class="login-box">
    <img class="brand-login-logo" src="assets/images/nelson-bg-logo.png">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="custom-float-input">
        <input type="text" min="1" formControlName="email" id="email" autofocus>
        <label>Email address</label>
      </div>
      <div class="custom-float-input">
        <input type="password" min="1" formControlName="password" id="password" autofocus>
        <label>Password</label>
      </div>
      <div class="forgot-btn">
        <a routerLink="/forgot">Forgot password?</a>
      </div>
      <button type="submit" [disabled]="disabledBtnWhileCallingAPI" class="btn btn-green full-width">Sign in</button>
    </form>
  </div>
</div>

<custom-modal
  modalName="updatePasswordModal"
  modalTitle="Update password"
  modalOrder="0"
  >
  <div class="custom-modal-body">
    <form [formGroup]="updateForm">
      <div class="password-criteria" style="padding-bottom: 20px;">You are required to change your account password when signing in your account for the first time.</div>
      <div class="custom-float-input">
        <input type="password" min="1" formControlName="newPassword" id="newPassword" autofocus>
        <label>{{ content.newPasswordLabel }}</label>
      </div>
      <div class="custom-float-input">
        <input type="password" min="1" formControlName="confirmNewPassword" id="confirmNewPassword" autofocus>
        <label>{{ content.confirmNewPasswordLabel }}</label>
      </div>
      <div class="password-criteria">
        <p>Password must contain at least:</p>
        <ul>
          <li>At least 8 characters—the more characters, the better</li>
          <li>A mixture of both uppercase and lowercase letters</li>
          <li>Contains at least one number and one special character</li>
        </ul>
      </div>
    </form>
  </div>
  <div class="custom-modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="closeCustomModal('updatePasswordModal')">Cancel</button>
    <button type="button" [disabled]="disabledBtnWhileCallingAPI" class="btn btn-green" (click)="updatePassword()">Update password</button>
  </div>
</custom-modal>

<!-- Result alert -->
<custom-modal
  modalName="resultAlert"
  modalSize="alert-modal"
  modalOrder="101"
  [backdropClose]="false"
  [alertType]="resultElements.type"
  >
  <div class="custom-modal-body">
    <div class="alert-title">{{resultElements.title}}</div>
    <div class="alert-desc">{{resultElements.desc}}</div>
  </div>
  @if (!resultElements.hideFooter) {
    <div class="custom-modal-footer">
      <button type="button" class="btn btn-green" (click)="closeResultAlert()">Close</button>
    </div>
  }
</custom-modal>
<!-- Loading alert -->
<custom-modal
  modalName="loadingAlert"
  modalSize="alert-modal"
  modalOrder="100"
  [backdropClose]="false"
  alertType="loading"
  >
  <div class="custom-modal-body">
    <div class="alert-title">{{loadingElements.title}}</div>
    @if (loadingElements.desc) {
      <div class="alert-desc">{{loadingElements.desc}}</div>
    }
  </div>
</custom-modal>